export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      home: {
        message: 'Olá Mundo!'
      },
      sankey: {
        sem_dados: 'Sem dados disponíveis',
        col1tab1: 'Cidade Hub por Proporção de Caminho',
        col2tab1: 'Estado da Cidade Hub',
        col3tab1: 'Código IBGE da Cidade Hub',
        col4tab1: 'Proporção de Caminhos que Levam ao Hub',
        col1tab2: 'Cidade de Entrada',
        col2tab2: 'Código IBGE da Cidade de Entrada',
        col3tab2: 'Cidade Afetada',
        col4tab2: 'Unidade Federativa da Cidade Afetada',
        col5tab2: 'Código IBGE da Cidade Afetada',
        col6tab2: 'Proporção de Caminhos que Levam às Cidades Afetadas',
        col1tab3: 'Cidades Sugeridas para Detecção Precoce de Patógenos na Cidade Selecionada',
        col2tab3: 'Estado da Cidade Hub',
        col3tab3: 'Código IBGE da Cidade Hub',
        col4tab3: 'Proporção de Caminhos que Levam ao Hub',
      },
      dashboard: {
        //title: 'Monitoramento das Infecções Respiratórias Atenção Primária em Saúde (APS) no Brasil',
        //subtitle: 'Visão Geral dos Atendimentos por Infecções de Via Aérea Superior (Gripais)',
        otc: 'OTC',
        a: 'a',
        e: 'e',
        title: 'Monitoramento dos Atendimentos por Provável Síndrome Gripal na Atenção Primária em Saúde (APS) no Brasil',
        subtitle: '',
        title_resumo: 'Painel Resumo',
        title_climate: 'Painel Climatico',
        queda: 'Queda',
        estavel: 'Estável',
        alerta: 'Atenção',
        alta : 'Alta',
        data : 'Data',
        buscar_uf: 'Filtrar UF',
        buscar_municipio: 'Filtrar Municipio',
        cidade : 'Município',
        estado : 'Estado',
        populacao: 'População',
        atencao: 'Atenção',
        aviso: 'Aviso',
        semana_ep: 'Semana Epidemiológica',
        semana: 'Semana',
        semanas: 'Semanas',
        tosse:'Tosse',
        febre:'Febre',
        dqi: 'DQI',
        ears: 'EARS',
        evi_simples: 'EVI',
        ambos: 'Ambos',
        sem_aviso: 'Sem Aviso',
        apto: 'Validado',
        nao_apto: 'Não Validados',
        no_data: 'Sem Dados',
        aptos: 'Validados',
        nao_aptos: 'Não Validados',
        nao_validado: 'Não Validado',
        probabilidade_crescimento: 'Probabilidade de Crescimento',
        aviso_consecutivo: 'Aviso Consecutivo',
        sem_informacao: 'Sem Informação',
        titulo_mapa_taxa_crescimento: 'Taxa de Crescimento de Provável Sindrome Gripal',
        titulo_mapa_atendimentos_excedentes : 'Provável Sindrome Gripal Excedente',
        titulo_mapa_evi: 'Sinal de Aviso',
        evi: 'EVI (Índice de Volatilidade Epidêmica)',
        legenda_mapa_taxa_crescimento: 'Taxa de Crescimento (%)',
        legenda_mapa_atendimentos_excedentes: 'Atendimentos Excedentes',
        perc_completude: 'Completude (%)',
        perc_tempestividade: 'Tempestividade (%)',
        retangulos:{
          titulo_excesso : 'Excesso de Provável Sindrome Gripal',
          mil: 'K',
          milhoes: 'M',
          bilhoes: 'G',
        },
        dados_climaticos: 'Dados Climáticos',
        graficos: {
          titulo_mm: 'Proporção de Provável Sindrome Gripal',
          legenda_mm: {
            1: 'Média Móvel',
            2: 'Proporção (Provável Sindrome Gripal / Total de atendimentos)'
          },
          titulo_variacao_mm : 'Taxa de Crescimento da Proporção de Provável Sindrome Gripal (Média Móvel)',
          titulo_atendimento_gripais : 'Provável Sindrome Gripal na APS',
          titulo_atendimento_totais : 'Total de Atendimentos na APS',
          titulo_excesso : 'Excesso de Provável Sindrome Gripal do Limite Esperado',
          titulo_limite : 'Limite Esperado de Provável Sindrome Gripal',
          titulo_precipitacao : 'Precipitação',
          titulo_humidade : 'Umidade',
          titulo_indice_calor :'Índice de Calor',
          titulo_temp2m :'Temperatura a 2 Metros',
          titulo_med_movel_precipitacao : 'Média Móvel Precipitação',
          titulo_med_movel_humidade : 'Média Móvel Umidade',
          titulo_med_movel_indice_calor :'Média Móvel Índice de Calor',
          titulo_med_movel_temp2m :'Média Móvel Temperatura a 2 Metros',
          titulo_tosse_febre: 'Tosse e Febre',
          titulo_prec: 'Precipitação Total Corrigida mm',
          titulo_temp: 'Temperatura Média Diária ºC',
          titulo_um: 'Umidade Relativa do Ar %',
        },
        table: {
          titulo: 'Consulta dos Indicadores nos Municípios',
          titulo_estados: 'Consulta dos Indicadores nos Municípios',
          col1: 'Município​',
          col2: 'População',
          col3: 'Taxa de Crescimento (Proporção Gripais) %',
          col9: 'EVI (Índice de Volatilidade Epidêmica)',
          col4: 'Excesso de Atendimentos (Gripais)',
          col5: 'Limite Superior Esperado de Atendimentos (Gripais)',
          col6: 'Proporção de Provável Sindrome Gripal',
          col7: 'Provável Sindrome Gripal',
          col8: 'Atendimentos Totais',
          estado: 'UF',
          limite: 'Limite',
          limite_ambos: 'Limite EVI & EARS',
          excesso: 'Excesso',
          excesso_ambos: 'Excesso EVI & EARS',
          evi: 'EVI',
          ears: 'EARS',
          ens: 'ENSEMBLE',
          ml: 'ML',
          glm: 'GLM',
          sinal_evi: 'Sinal EVI Consecutivo',
          sinal_ears: 'Sinal EARS Consecutivo',
          sinal_ambos: 'Sinal EVI e Ears Consecutivo',
          sinal_ens: 'Sinal ENSEMBLE Consecutivo',
          sinal_ml: 'Sinal ML Consecutivo',
          excesso_ml: 'Excesso ML',
          limite_ml: 'Limite ML',
          sinal_glm: 'Sinal GLM Consecutivo',
          limite_glm: 'Limite GLM',
          excesso_glm: 'Excesso GLM',
          sinais: 'Total de Sinais',
        },
        todos: 'Todos',
        mensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Não foi possível calcular taxa de crescimento',
        subMensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Indef',
        mesAno: 'Mês/Ano: ',
        flowMapNomeLayerMob: 'Rotas de Mobilidade',
        semanal: 'Semanal',
        mensal: 'Mensal',
        excesso: 'Excesso de Provável Sindrome Gripal do Limite Esperado',
        excesso_simples: 'Excesso',
        gripe: 'Gripe',
        arbo: 'Arbo',
        legenda: 'Legenda',
        variaveisClima: {
          Bio01BR: "Temperatura Média Anual do Ar",
          Bio02BR: "Faixa Média de Temperatura Diurna do Ar",
          Bio03BR: "Isotermalidade",
          Bio04BR: "Sazonalidade da Temperatura",
          Bio05BR: "Média da Temperatura Máxima Diária do Ar do Mês Mais Quente",
          Bio06BR: "Temperatura Mínima Média Diária do Mês Mais Frio",
          Bio07BR: "Variação Anual da Temperatura do Ar",
          Bio08BR: "Temperaturas Médias Diárias do Ar do Trimestre Mais Chuvoso",
          Bio09BR: "Temperaturas Médias Diárias do Ar do Trimestre Mais Seco",
          Bio10BR: "Temperaturas Médias Diárias do Ar do Trimestre Mais Quente",
          Bio11BR: "Temperaturas Médias Diárias do Ar do Trimestre Mais Frio",
          Bio12BR: "Quantidade Anual de Precipitação",
          Bio13BR: "Quantidade de Precipitação do Mês Mais Chuvoso",
          Bio14BR: "Quantidade de Precipitação do Mês Mais Seco",
          Bio15BR: "Sazonalidade da Precipitação",
          Bio16BR: "Quantidade Mensal Média de Precipitação do Trimestre Mais Chuvoso",
          Bio17BR: "Quantidade Mensal Média de Precipitação do Trimestre Mais Seco",
          Bio18BR: "Quantidade Mensal Média de Precipitação do Trimestre Mais Quente",
          Bio19BR: "Quantidade Mensal Média de Precipitação do Trimestre Mais Frio",
        },
        variaveisGoogle: {
          coriza: "Coriza",
          covid: "Covid",
          diarreia: "Diarreia",
          dor_de_cabeca: "Dor de Cabeça",
          dor_de_garganta: "Dor de Garganta",
          falta_de_ar: "Falta de Ar",
          febre: "Febre",
          gripe: "Gripe",
          resfriado: "Resfriado",
          tosse: "Tosse",
          vomito: "Vômito",
          termos: "Termos",
        },
        variavel: "Variável",
      }
    }
  }