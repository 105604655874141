import './NavLinks.css'
import Nav from 'react-bootstrap/Nav';

const NavLinks = (props) => {
        if(true){
                return (<div className='preenche_espaco'></div>)
        }
        else
        if(props.linguagem === "pt"){
                return ( 
                        <Nav>
                        <Nav.Link href="login">Painel</Nav.Link>
                        <Nav.Link href="news">Notícias</Nav.Link>
                        <Nav.Link href="#tutorial">Tutorial</Nav.Link>
                        <Nav.Link href="boletins">Boletins</Nav.Link>
                        <Nav.Link target='_blank' href="https://aesop-data-documentation.readthedocs.io/en/latest/index.html">Documentação</Nav.Link>
                        <Nav.Link target='_blank' href="https://github.com/cidacslab">Repositórios</Nav.Link>
                        </Nav> 
                )
        } else if(props.linguagem === "en") {
                return ( 
                        <Nav>
                        <Nav.Link href="login">Dashboard</Nav.Link>
                        <Nav.Link href="news">News</Nav.Link>
                        <Nav.Link href="#tutorial">Tutorial</Nav.Link>
                        <Nav.Link href="boletins">Bulletins</Nav.Link>
                        <Nav.Link target='_blank' href="https://aesop-data-documentation.readthedocs.io/en/latest/index.html">Documentation</Nav.Link>
                        <Nav.Link target='_blank' href="https://github.com/cidacslab">Repositories</Nav.Link>
                        </Nav> 
                )
        }
    
}

export default NavLinks