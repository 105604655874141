import React from 'react'
import "./NotFound.css";

function NotFound() {
  return (
    <div className='container_not_found'>
        <h1>Error 404</h1>
        Could not find this page.
    </div>
  )
}

export default NotFound