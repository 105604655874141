//crie a estrutura basica
import React from 'react';
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../Globalstyle";
import { lightTheme, darkTheme } from "../Themes"
import { Routes, Route } from "react-router-dom";
import Login from '../Login';
import { useTranslation } from 'react-i18next';
import NotFound from '../NotFound/NotFound';
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import {useContext, useRef, useState, useMemo, useEffect} from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import style from './WebBased.module.css'
import { FormControl, InputLabel,MenuItem, Select, Autocomplete, Box, Slider, TextField } from '@mui/material';
//import { MenuItem, Select, Box, FormControl, InputLabel, MenuItem, Select, Slider, TextField, OutlinedInput, Stack, Chip, Autocomplete, Tabs, Paper, Tab } from '@mui/material';
import './WebBased.css';
import Translator from '../I18n/Translator';
import { addDays, startOfYear, startOfWeek, endOfWeek,format, setWeek, getISOWeek, differenceInCalendarDays } from 'date-fns';
import { enUS, te } from 'date-fns/locale';



function WebBased() {
    const dadosCoriza = useRef([]);
  const dadosCovid = useRef([]);
  const dadosDiarreia = useRef([]);
  const dadosDorDeCabeca = useRef([]);
  const dadosDorDeGarganta = useRef([]);
  const dadosFaltaDeAr = useRef([]);
  const dadosFebre = useRef([]);
  const dadosGripe = useRef([]);
  const dadosResfriado = useRef([]);
  const dadosTosse = useRef([]);
  const dadosVomito = useRef([]);
  const dadosGripal = useRef([]);

  const dadosCorizaMediaMovel = useRef([]);
  const dadosCovidMediaMovel = useRef([]);
  const dadosDiarreiaMediaMovel = useRef([]);
  const dadosDorDeCabecaMediaMovel = useRef([]);
  const dadosDorDeGargantaMediaMovel = useRef([]);
  const dadosFaltaDeArMediaMovel = useRef([]);
  const dadosFebreMediaMovel = useRef([]);
  const dadosGripeMediaMovel = useRef([]);
  const dadosResfriadoMediaMovel = useRef([]);
  const dadosTosseMediaMovel = useRef([]);
  const dadosVomitoMediaMovel= useRef([]);
  const dadosGripalMediaMovel = useRef([]);
  const prioridade = useRef([]);

  const [datas, setDatas] = useState([]);
  const [minIdxDate, setMinIdxDate] = useState(0);
  const [maxIdxDate, setMaxIdxDate] = useState(0);

  var texto_grafico_dark = "#fff";
  var texto_grafico_light = "#000";
  var fontGrafico = 10;

  var cod_estados = [
    {"UF":"AC",
    "COD": 12, "NOME":"Acre"},
    {"UF":"AL",
    "COD": 27, "NOME":"Alagoas"},
    {"UF":"AP",
    "COD": 16, "NOME":"Amapá"},
    {"UF":"AM",
    "COD": 13, "NOME":"Amazonas"},
    {"UF":"BA",
    "COD": 29, "NOME":"Bahia"},
    {"UF":"CE",
    "COD": 23, "NOME":"Ceará"},
    {"UF":"DF",
    "COD": 53, "NOME":"Distrito Federal"},
    {"UF":"ES",
    "COD": 32, "NOME":"Espírito Santo"},
    {"UF":"GO",
    "COD": 52, "NOME":"Goiás"},
    {"UF":"MA",
    "COD": 21, "NOME":"Maranhão"},
    {"UF":"MT",
    "COD": 51, "NOME":"Mato Grosso"},
    {"UF":"MS",
    "COD": 50, "NOME":"Mato Grosso do Sul"},
    {"UF":"MG",
    "COD": 31, "NOME":"Minas Gerais"},
    {"UF":"PA",
    "COD": 15, "NOME":"Pará"},
    {"UF":"PB",
    "COD": 25, "NOME":"Paraíba"},
    {"UF":"PR",
    "COD": 41, "NOME":"Paraná"},
    {"UF":"PE",
    "COD": 26, "NOME":"Pernambuco"},
    {"UF":"PI",
    "COD": 22, "NOME":"Piauí"},
    {"UF":"RJ",
    "COD": 33, "NOME":"Rio de Janeiro"},
    {"UF":"RN",
    "COD": 24, "NOME":"Rio Grande do Norte"},
    {"UF":"RS",
    "COD": 43, "NOME":"Rio Grande do Sul"},
    {"UF":"RO",
    "COD": 11,"NOME":"Rondônia"},
    {"UF":"RR",
    "COD": 14,"NOME":"Roraima"},
    {"UF":"SC",
    "COD": 42,"NOME":"Santa Catarina"},        
    {"UF":"SP",
    "COD": 35,"NOME":"São Paulo"},
    {"UF":"SE",
    "COD": 28,"NOME":"Sergipe"},
    {"UF":"TO",
    "COD": 17,"NOME":"Tocantins"},
];

  const [ocultaCoriza, setOcultaCoriza] = useState(false);
  const [ocultaCovid, setOcultaCovid] = useState(false);
  const [ocultaDiarreia, setOcultaDiarreia] = useState(false);
  const [ocultaDorDeCabeca, setOcultaDorDeCabeca] = useState(false);
  const [ocultaDorDeGarganta, setOcultaDorDeGarganta] = useState(false);
  const [ocultaFaltaDeAr, setOcultaFaltaDeAr] = useState(false);
  const [ocultaFebre, setOcultaFebre] = useState(false);
  const [ocultaGripe, setOcultaGripe] = useState(false);
  const [ocultaResfriado, setOcultaResfriado] = useState(false);
  const [ocultaTosse, setOcultaTosse] = useState(false);
  const [ocultaVomito, setOcultaVomito] = useState(false);
  const [ocultaGripal, setOcultaGripal] = useState(false);

  const [exibeBotoes, setExibeBotoes] = useState(true);

  const atualizandoGrafico = useRef(false);
  const contadorReescala = useRef(0);

  const chartRef = useRef(null);

  const legenda = [];

  const {theme} = useContext(TemaContext);
  const {language} = useContext(LinguagemContext);
  const { t } = useTranslation();

  
  const [mediaMovelSelecionada, setMediaMovelSelecionada] = useState("1");
  const [variavelMediaMovelSelecionada, setVariavelMediaMovelSelecionada] = useState("1");

  const darkenRGBA = (rgbaStr, percentage) => {
    // Parse the RGBA string
    const rgba = rgbaStr.match(/\d+/g).map(Number);
    const [r, g, b, a] = rgba;
  
    // Calculate the darkened values
    const factor = 1 - (percentage / 100);
    const newR = Math.max(0, Math.min(255, Math.floor(r * factor)));
    const newG = Math.max(0, Math.min(255, Math.floor(g * factor)));
    const newB = Math.max(0, Math.min(255, Math.floor(b * factor)));
  
    // Return the new RGBA string
    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  };

  const normalizaDados = (data) => {
    //const dates = Object.keys(data);
    
    let dataInicial = datas[minIdxDate];
    let dataFinal = datas[maxIdxDate];

    const dates = Object.keys(data).filter(date => date >= dataInicial && date <= dataFinal);

    if (dates.length === 0) return [];
  
    // Extrair os valores de 'total'
    let valores = dates.map(date => data[date]);
  
    // Encontrar o valor máximo em 'total'
    let max = Math.max(...valores);

    let multiplicador = 100 / max;
  
    // Normalizar os valores de 'total' e manter os campos 'data'
    let normalizedData = dates.map(date => {
      return {
        data: date,
        total : data[date] * multiplicador
      };
    });

    normalizedData = Object.fromEntries(
      normalizedData.map(({data, total}) => [data, total])
    );
  
    return normalizedData;
  };

  const getDadosGrafico = (
    dados_total_atendimentos_gripais, dados, fullScreen, 
    titulo_atendimento_gripais, titulo, borderColor2, 
    backgroundColor2) => {
    return {
        legenda,
        datasets: [
            {
                fill: true,
                label: titulo_atendimento_gripais,
                data: dados_total_atendimentos_gripais,
                borderColor: corAtendimentosGripais,
                backgroundColor: corBackgroundAtendimentosGripais,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: fullScreen ? 1.5 : 1.3,
                yAxisID: 'y',
            },
            {
                fill: true,
                label: titulo,
                data: dados,
                borderColor: borderColor2,
                backgroundColor: backgroundColor2,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: fullScreen ? 1.5 : 1.3,
                yAxisID: 'y2',
            },
            
        ],

    };
  }

  const getDadosGraficoTodos = (titulo_atendimento_gripais, dados_total_atendimentos_gripais, titulo, dados, titulo2, dados2, titulo3, dados3, titulo4, dados4, titulo5, dados5, titulo6, dados6, titulo7, dados7, titulo8, dados8, titulo9, dados9, titulo10, dados10, titulo11, dados11, fullScreen) => {
    return {
        legenda,
        datasets: [
            {
                cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo_atendimento_gripais,
                data: dados_total_atendimentos_gripais,
                borderColor: /*corAtendimentosGripais*/'red',
                backgroundColor: /*corBackgroundAtendimentosGripais*/ 'red',
                borderWidth: fullScreen ? 6 : 3,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo,
                data: dados,
                borderColor: borderColor2,
                backgroundColor: backgroundColor2,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                 yAxisID: 'y2',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo2,
                data: dados2,
                borderColor: borderColor3,
                backgroundColor: backgroundColor3,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
                cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo3,
                data: dados3,
                borderColor: borderColor4,
                backgroundColor: backgroundColor4,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
              cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo4,
                data: dados4,
                borderColor: borderColor5,
                backgroundColor: backgroundColor5,
                borderWidth: fullScreen ? 2 : 1,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y2',
                hidden:false,
            },
            {
              cubicInterpolationMode: 'monotone',  
              fill: false,
              label: titulo5,
              data: dados5,
              borderColor: borderColor6,
              backgroundColor: backgroundColor6,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo6,
              data: dados6,
              borderColor: borderColor7,
              backgroundColor: backgroundColor7,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',  
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo7,
              data: dados7,
              borderColor: borderColor8,
              backgroundColor: backgroundColor8,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo8,
            data: dados8,
            borderColor: borderColor9,
            backgroundColor: backgroundColor9,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo9,
              data: dados9,
              borderColor: borderColor10,
              backgroundColor: backgroundColor10,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo10,
              data: dados10,
              borderColor: borderColor11,
              backgroundColor: backgroundColor11,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          },
          {
            cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo11,
              data: dados11,
              borderColor: borderColor12,
              backgroundColor: backgroundColor12,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          }, 
        ],

    };
  }

  const getDadosMediaGraficoTodos = (titulo_atendimento_gripais, dados_total_atendimentos_gripais, titulo, dados, titulo2, dados2, titulo3, dados3, titulo4, dados4, titulo5, dados5, titulo6, dados6, titulo7, dados7, titulo8, dados8, titulo9, dados9, titulo10, dados10, titulo11, dados11, fullScreen) => {
    let corMediaMovelAzul = "#003964";
    let borderWidthMediaMovel = fullScreen ? 4 : 2;
    let pointBorderWidthMediaMovel = fullScreen ? 4 : 3;
    let dataLocal = [];
    let dataLocalMediaMovel = [];
    switch (variavelMediaMovelSelecionada) {
        case "1":
          dataLocal = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: titulo,
              data: normalizaDados(dadosCoriza.current),
              borderColor: borderColor2,
              backgroundColor:  backgroundColor2,
              borderWidth: fullScreen ? 2 : 1,
              pointBorderWidth: fullScreen ? 2 : 1.5,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo,
              data: dados,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "2":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo2,
            data: normalizaDados(dadosCovid.current),
            borderColor: borderColor3,
            backgroundColor: backgroundColor3,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo2,
              data: dados2,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "3":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo3,
            data: normalizaDados(dadosDiarreia.current),
            borderColor: borderColor4,
            backgroundColor: backgroundColor4,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo3,
              data: dados3,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "4":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo4,
            data: normalizaDados(dadosDorDeCabeca.current),
            borderColor: borderColor5,
            backgroundColor: backgroundColor5,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo4,
              data: dados4,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "5":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo5,
            data: normalizaDados(dadosDorDeGarganta.current),
            borderColor: borderColor6,
            backgroundColor: backgroundColor6,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo5,
              data: dados5,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "6":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo6,
            data: normalizaDados(dadosFaltaDeAr.current),
            borderColor: borderColor7,
            backgroundColor: backgroundColor7,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " +titulo6,
              data: dados6,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "7":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo7,
            data: normalizaDados(dadosFebre.current),
            borderColor: borderColor8,
            backgroundColor: backgroundColor8,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo7,
              data: dados7,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "8":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo8,
            data: normalizaDados(dadosGripe.current),
            borderColor: borderColor9,
            backgroundColor: backgroundColor9,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo8,
              data: dados8,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "9":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo9,
            data: normalizaDados(dadosResfriado.current),
            borderColor: borderColor10,
            backgroundColor: backgroundColor10,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo9,
              data: dados9,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "10":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo10,
            data: normalizaDados(dadosTosse.current),
            borderColor: borderColor11,
            backgroundColor: backgroundColor11,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo10,
              data: dados10,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
        case "11":
          dataLocal = {
            cubicInterpolationMode: 'monotone',
            fill: false,
            label: titulo11,
            data: normalizaDados(dadosVomito.current),
            borderColor: borderColor12,
            backgroundColor: backgroundColor12,
            borderWidth: fullScreen ? 2 : 1,
            pointBorderWidth: fullScreen ? 2 : 1.5,
            pointRadius: 0,
            yAxisID: 'y2',
            hidden:false,
        };
          dataLocalMediaMovel = {
              cubicInterpolationMode: 'monotone',
              fill: false,
              label: t("dashboard.graficos.legenda_mm.1") + " " + titulo11,
              data: dados11,
              borderColor: corMediaMovelAzul,
              backgroundColor: corMediaMovelAzul,
              borderWidth: borderWidthMediaMovel,
              pointBorderWidth: pointBorderWidthMediaMovel,
              pointRadius: 0,
              yAxisID: 'y2',
              hidden:false,
          };
          break;
    }
    return {
        legenda,
        datasets: [
            {
                cubicInterpolationMode: 'monotone',
                fill: false,
                label: titulo_atendimento_gripais,
                data: dados_total_atendimentos_gripais,
                borderColor: /*corAtendimentosGripais*/'red',
                backgroundColor: /*corBackgroundAtendimentosGripais*/ 'red',
                borderWidth: fullScreen ? 6 : 3,
                pointBorderWidth: fullScreen ? 2 : 1.5,
                pointRadius: 0,
                yAxisID: 'y',
                hidden:false,
            },
            dataLocal,
            dataLocalMediaMovel,
        ],

    };
  }

  const corAtendimentosGripais = useMemo(() => {
    return '#2fb4ef';
  }, []);   

  const corBackgroundAtendimentosGripais = useMemo(() => {
    return 'rgba(47, 180, 239, 0.5)';
  }, []); 

  const borderColor2 = useMemo(() => {
    return "#E6194B"; // Vermelho
}, []);

const backgroundColor2 = useMemo(() => { 
    return "rgba(230, 25, 75, 0.5)";
}, []);

const borderColor2_mm = useMemo(() => {
  return "#ab1338"; // Vermelho
}, []);

const backgroundColor2_mm = useMemo(() => { 
  return "rgba(171, 19, 56, 0.5)";
}, []);

const borderColor3 = useMemo(() => {
    return "#3CB44B"; // Verde
}, []);

const backgroundColor3 = useMemo(() => { 
    return "rgba(60, 180, 75, 0.5)";
}, []);

const borderColor3_mm = useMemo(() => {
  return "#2b8236"; // Verde
}, []);

const backgroundColor3_mm = useMemo(() => { 
  return "rgba(43, 130, 54, 0.5)";
}, []);

const borderColor4 = useMemo(() => {
    return "#FFE119"; // Amarelo
}, []);

const backgroundColor4 = useMemo(() => { 
    return "rgba(255, 225, 25, 0.5)";
}, []);

const borderColor4_mm = useMemo(() => {
  return "#bda713"; // Amarelo
}, []);

const backgroundColor4_mm = useMemo(() => { 
  return "rgba(189, 167, 19, 0.5)";
}, []);

const borderColor5 = useMemo(() => {
    return "#4363D8"; // Azul
}, []);

const backgroundColor5 = useMemo(() => { 
    return "rgba(70, 130, 180, 0.5)";
}, []);

const borderColor5_mm = useMemo(() => {
  return "#334ca6"; // Azul
}, []);

const backgroundColor5_mm = useMemo(() => { 
  return "rgba(51, 76, 166, 0.5)";
}, []);

const borderColor6 = useMemo(() => {
    return "#F58231"; // Laranja
}, []);

const backgroundColor6 = useMemo(() => { 
    return "rgba(245, 130, 48, 0.5)";
}, []);

const borderColor6_mm = useMemo(() => {
  return "#c96b28"; // Laranja
}, []);

const backgroundColor6_mm = useMemo(() => { 
  return "rgba(201, 107, 40, 0.5)";
}, []);

const borderColor7 = useMemo(() => {
    return "#911EB4"; // Roxo
}, []);

const backgroundColor7 = useMemo(() => { 
    return "rgba(145, 30, 180, 0.5)";
}, []);

const borderColor7_mm = useMemo(() => {
    return "#73178f"; // Roxo
}, []);

const backgroundColor7_mm = useMemo(() => {
    return "rgba(115, 23, 143, 0.5)";
}, []);

const borderColor8 = useMemo(() => {
    return "#46F0F0"; // Ciano
}, []);

const backgroundColor8 = useMemo(() => { 
    return "rgba(70, 240, 240, 0.5)";
}, []);

const borderColor8_mm = useMemo(() => {
  return "#35b8b8"; // Ciano
}, []);

const backgroundColor8_mm = useMemo(() => {
  return "rgba(53, 184, 184, 0.5)";
}, []);

const borderColor9 = useMemo(() => {
    return "#F032E6"; // Magenta
}, []);

const backgroundColor9 = useMemo(() => { 
    return "rgba(240, 50, 230, 0.5)";
}, []);

const borderColor9_mm = useMemo(() => {
  return "#b526ae"; // Magenta
}, []);

const backgroundColor9_mm = useMemo(() => { 
  return "rgba(181, 38, 174, 0.5)";
}, []);

const borderColor10 = useMemo(() => {
    return "#800000"; // Marrom
}, []);

const backgroundColor10 = useMemo(() => { 
    return "rgba(128, 0, 0, 0.5)";
}, []);

const borderColor10_mm = useMemo(() => {
  return "#6e0101"; // Marrom
}, []);

const backgroundColor10_mm = useMemo(() => { 
  return "rgba(110, 1, 1, 0.5)";
}, []);

const borderColor11 = useMemo(() => {
    return "#808000"; // Oliva
}, []);

const backgroundColor11 = useMemo(() => { 
    return "rgba(128, 128, 0, 0.5)";
}, []);

const borderColor11_mm = useMemo(() => {
  return "#4f4f00"; // Oliva
}, []);

const backgroundColor11_mm = useMemo(() => { 
  return "rgba(79, 79, 0, 0.5)";
}, []);

const borderColor12 = useMemo(() => {
    return "#808080"; // Cinza
}, []);

const backgroundColor12 = useMemo(() => { 
    return "rgba(128, 128, 128, 0.5)";
}, []);

const borderColor12_mm = useMemo(() => {
  return "#404040"; // Cinza
}, []);

const backgroundColor12_mm = useMemo(() => { 
  return "rgba(64, 64, 64, 0.5)";
}, []);

  const [dadosAtendimentoCoriza, setDadosAtendimentoCoriza] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", t("dashboard.variaveisGoogle.coriza"))
  );

  const [dadosAtendimentoCovid, setDadosAtendimentoCovid] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Covid")
  );

  const [dadosAtendimentoDiarreia, setDadosAtendimentoDiarreia] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Diarreia")
  );

  const [dadosAtendimentoDorDeCabeca, setDadosAtendimentoDorDeCabeca] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Cabeça")
  );

  const [dadosAtendimentoDorDeGarganta, setDadosAtendimentoDorDeGarganta] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Garganta")
  );

  const [dadosAtendimentoFaltaDeAr, setDadosAtendimentoFaltaDeAr] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Falta de Ar")
  );

  const [dadosAtendimentoFebre, setDadosAtendimentoFebre] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Febre")
  );

  const [dadosAtendimentoGripe, setDadosAtendimentoGripe] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Gripe")
  );

  const [dadosAtendimentoResfriado, setDadosAtendimentoResfriado] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Resfriado")
  );

  const [dadosAtendimentoTosse, setDadosAtendimentoTosse] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Tosse")
  );

  const [dadosAtendimentoVomito, setDadosAtendimentoVomito] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Vomito")
  );

  const [dadosAtendimentoTodos, setDadosAtendimentoTodos] = useState(
    getDadosGraficoTodos("Atendimentos Gripais", null, t("dashboard.variaveisGoogle.coriza"), null, "Covid", null, "Diarreia", null, "Dor de Cabeça", null, "Dor de Garganta", null, "Falta de Ar", null, "Febre", null, "Gripe", null, "Resfriado", null, "Tosse", null, "Vomito", null, false)
  );

  const [dadosMediaAtendimentoTodos, setDadosMediaAtendimentoTodos] = useState(
    getDadosMediaGraficoTodos("Atendimentos Gripais", null, t("dashboard.variaveisGoogle.coriza"), null, "Covid", null, "Diarreia", null, "Dor de Cabeça", null, "Dor de Garganta", null, "Falta de Ar", null, "Febre", null, "Gripe", null, "Resfriado", null, "Tosse", null, "Vomito", null, false)
  );

  const [dadosAtendimentoCorizaMediaMovel, setDadosAtendimentoCorizaMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", t("dashboard.variaveisGoogle.coriza"))
  );

  const [dadosAtendimentoCovidMediaMovel, setDadosAtendimentoCovidMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Covid")
  );

  const [dadosAtendimentoDiarreiaMediaMovel, setDadosAtendimentoDiarreiaMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Diarreia")
  );

  const [dadosAtendimentoDorDeCabecaMediaMovel, setDadosAtendimentoDorDeCabecaMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Cabeça")
  );

  const [dadosAtendimentoDorDeGargantaMediaMovel, setDadosAtendimentoDorDeGargantaMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Dor de Garganta")
  );

  const [dadosAtendimentoFaltaDeArMediaMovel, setDadosAtendimentoFaltaDeArMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Falta de Ar")
  );

  const [dadosAtendimentoFebreMediaMovel, setDadosAtendimentoFebreMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Febre")
  );

  const [dadosAtendimentoGripeMediaMovel, setDadosAtendimentoGripeMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Gripe")
  );

  const [dadosAtendimentoResfriadoMediaMovel, setDadosAtendimentoResfriadoMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Resfriado")
  );

  const [dadosAtendimentoTosseMediaMovel, setDadosAtendimentoTosseMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Tosse")
  );

  const [dadosAtendimentoVomitoMediaMovel, setDadosAtendimentoVomitoMediaMovel] = useState(
    getDadosGrafico(null, null, false, "Atendimentos Gripais", "Vomito")
  );

  const [dadosAtendimentoTodosMediaMovel, setDadosAtendimentoTodosMediaMovel] = useState(
    getDadosGraficoTodos("Atendimentos Gripais", null, "Coriza", null, "Covid", null, "Diarreia", null, "Dor de Cabeça", null, "Dor de Garganta", null, "Falta de Ar", null, "Febre", null, "Gripe", null, "Resfriado", null, "Tosse", null, "Vomito", null, false)
  );

  const [minDate, setMinDate] = useState(null);
  const [minDateRangeInicial, setMinDateRangeInicial] = useState(null);
  const [minDateRangeFinal, setMinDateRangeFinal] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [maxDateRangeInicial, setMaxDateRangeInicial] = useState(null);
  const [maxDateRangeFinal, setMaxDateRangeFinal] = useState(null);
  const [inicioSemanaSelecionada, setInicioSemanaSelecionada] = useState(null);
  const [fimSemanaSelecionada, setFimSemanaSelecionada] = useState(null);
  const [inicioSemanaSelecionadaRange, setInicioSemanaSelecionadaRange] = useState(null);
  const [fimSemanaSelecionadaRange, setFimSemanaSelecionadaRange] = useState(null);

  const [semanas, setSemanas] = useState([]);
  const [inicioSemanas, setInicioSemanas] = useState([]);
  const [fimSemanas, setFimSemanas] = useState([]);
  
  
  const dataInicial = useRef(new Date("2023-01-01"));

  const [valueRangeData, setValueRangeData] = useState([0,0]);
  
  function compararPorData(a, b) {
    const dataA = new Date(a.data);
    const dataB = new Date(b.data);
    return dataA - dataB;
  }
  /*
  A média da relação percentual (covid-gripe) é aproximadamente 21.35%
A média da relação percentual (gripe-febre) é aproximadamente 46.58%
A média da relação percentual (gripe-tosse) é aproximadamente 27.46%
A média da relação percentual (gripe-diarreia) é aproximadamente 19.66%
A média da relação percentual (gripe-dor_de_cabeca) é aproximadamente 13.46%
A média da relação percentual (gripe-coriza) é aproximadamente 6.76%
A média da relação percentual (gripe-dor_de_garganta) é aproximadamente 9.99%
A média da relação percentual (gripe-falta_de_ar) é aproximadamente 10.29%
A média da relação percentual (gripe-resfriado) é aproximadamente 11.25%
A média da relação percentual (gripe-vomito) é aproximadamente 4.91%
    */

  let media_gripe = {
    "febre": 0.4658,
    "tosse": 0.2746,
    "diarreia": 0.1966,
    "dor_de_cabeca": 0.1346,
    "resfriado": 0.1125,
    "falta_de_ar": 0.1029,
    "dor_de_garganta": 0.0999,
    "coriza": 0.0676,   
    "vomito":0.0491
  }
  
  let media_covid = {
    "gripe":0.2135,
    "febre": 0.4658*0.2135,
    "tosse": 0.2746*0.2135,
    "diarreia": 0.1966*0.2135,
    "dor_de_cabeca": 0.1346*0.2135,
    "resfriado": 0.1125*0.2135,
    "falta_de_ar": 0.1029*0.2135,
    "dor_de_garganta": 0.0999*0.2135,
    "coriza": 0.0676*0.2135,   
    "vomito":0.0491*0.2135
  }


  const atualizaRangeGraficos = (valor, tipo, validacao) => {
    if(validacao){
        if(tipo === "max"){
            if(parseInt(valor) <= (parseInt(minIdxDate) + 1)){
                return;
            }
        } else {
            if(parseInt(valor) >= (parseInt(maxIdxDate) - 1) ){
                valor = parseInt(maxIdxDate) - 2;
                //return;
            }
        } 
    }

    /*let maior = tipo === "max" ? parseInt(valor) : maxIdxDate;
    let menor = tipo === "min" ? parseInt(valor) : minIdxDate;

    var minPercent = getPercent(parseInt(menor));
    var maxPercent = getPercent(parseInt(maior));*/

    if (tipo === "max") {
        setMaxDate(datas[valor]);
        setFimSemanaSelecionada(fimSemanas[valor]);
        setMaxIdxDate(valor);
        setValueRangeData([minIdxDate,valor]);
        
        setInicioSemanaSelecionadaRange(semanas[minIdxDate]);
        setFimSemanaSelecionadaRange(semanas[valor]);

        setMinDateRangeInicial(inicioSemanas[minIdxDate]);
        setMinDateRangeFinal(fimSemanas[minIdxDate]);
        setMaxDateRangeInicial(inicioSemanas[valor]);
        setMaxDateRangeFinal(fimSemanas[valor]);

        /*setSizeRange({width: (maxPercent - minPercent) + "%", left: sizeRange.left});*/
        ////filtrarMunicipio(municipioSelecionado, false, props.data,"",datas[minIdxDate],datas[valor]);
        
    } else {
        setMinDate(datas[valor]);
        setMinDateRangeInicial(inicioSemanas[valor]);
        setMinDateRangeFinal(fimSemanas[valor]);
        setMaxDateRangeInicial(inicioSemanas[maxIdxDate]);
        setMaxDateRangeFinal(fimSemanas[maxIdxDate]);

        setInicioSemanaSelecionadaRange(semanas[valor]);
        setFimSemanaSelecionadaRange(semanas[maxIdxDate]);
        setMinIdxDate(valor);

        /*let idxDataInicio = getIdxDataInicio(datas);
        setMinDate(datas[idxDataInicio]);
        setMinIdxDate(idxDataInicio);*/
        
        setValueRangeData([valor,maxIdxDate]);

        /*
        if(maxPercent > 85)
            maxPercent = 85;*/

        /*setSizeRange({width: (maxPercent - minPercent) + "%", left: minPercent+ "%"});*/
        ////filtrarMunicipio(municipioSelecionado, false,props.data,"",datas[valor],datas[maxIdxDate]);
    }
  }

  const handleChangeRangeData = (event, newValue) => {
    if(newValue[0] !== minIdxDate){
        atualizaRangeGraficos(newValue[0], "min", true);
    } else if(newValue[1] !== maxIdxDate) {    
        atualizaRangeGraficos(newValue[1], "max", true);
    }
  };

  const isAllSelect = (variavel) => {
    return variavel === "All" || variavel === "Todos";
  }

  function calculo_sintomas_relativos(termos, y1, y2, y3, y4, y5, y6, y7, y8, y9, y10, y11) {
    let y1_ = 0
    let y2_ = 0
    let y3_ = 0
    let y4_ = 0
    let y5_ = 0
    let y6_ = 0
    let y7_ = 0
    let y8_ = 0 
    let y9_ = 0
    let y10_ = 0
    let y11_ = 0

    if (termos[0] != "") {
        y1_ = y1 * prioridade.current[0]["perc"]
      if (termos[1] != "")
        y2_ = y2 * prioridade.current[1]["perc"]
      if (termos[2] != "")
        y3_ = y3 * prioridade.current[2]["perc"]
      if (termos[3] != "")
        y4_ = y4 * prioridade.current[3]["perc"]
      if (termos[4] != "")
        y5_ = y5 * prioridade.current[4]["perc"]
      if (termos[5] != "")
        y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
        
    else if (termos[1] != "") {
      y2_ = y2 * prioridade.current[1]["perc"]
      if (termos[2] != "")
        y3_ = y3 * prioridade.current[2]["perc"]
      if (termos[3] != "")
        y4_ = y4 * prioridade.current[3]["perc"]
      if (termos[4] != "")
        y5_ = y5 * prioridade.current[4]["perc"]
      if (termos[5] != "")
        y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
        
    else if (termos[2] != "") {
      /*y2_ = y2*/
      y3_ = y3 * prioridade.current[2]["perc"]
      if (termos[3] != "")
        y4_ = y4 * prioridade.current[3]["perc"]
      if (termos[4] != "")
        y5_ = y5 * prioridade.current[4]["perc"]
      if (termos[5] != "")
        y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }

    else if (termos[3] != "") {
      /*y2_ = y2
      y3_ = y3*/
      y4_ = y4 * prioridade.current[3]["perc"] 
      if (termos[4] != "")
        y5_ = y5 * prioridade.current[4]["perc"]
      if (termos[5] != "")
        y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
    else if (termos[4] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 */
      y5_ = y5 * prioridade.current[4]["perc"]
      if (termos[5] != "")
        y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
    else if (termos[5] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5*/
      y6_ = y6 * prioridade.current[5]["perc"]
      if (termos[6] != "")
        y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }

    else if (termos[6] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5
      y6_ = y6*/
      y7_ = y7 * prioridade.current[6]["perc"]
      if (termos[7] != "")
        y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }

    else if (termos[7] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5
      y6_ = y6
      y7_ = y7 */
      y8_ = y8 * prioridade.current[7]["perc"]
      if (termos[8] != "")
        y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
    
    else if (termos[8] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5
      y6_ = y6
      y7_ = y7 
      y8_ = y8*/
      y9_ = y9 * prioridade.current[8]["perc"]
      if (termos[9] != "")
        y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }

    else if (termos[9] != "") {
      /*y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5
      y6_ = y6
      y7_ = y7 
      y8_ = y8
      y9_ = y9*/
      y10_ = y10 * prioridade.current[9]["perc"]
      if (termos[10] != "")
        y11_ = y11 * prioridade.current[10]["perc"]
    }
    
    else if (termos[10] != "") {
      y2_ = y2
      y3_ = y3
      y4_ = y4 
      y5_ = y5
      y6_ = y6
      y7_ = y7 
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }
   
    else {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }

    return {y2_, y3_, y4_, y5_, y6_, y7_, y8_, y9_, y10_, y11_}
  }

 

  function calculo_sintomas(covid, gripe, febre, tosse, diarreia, dor_de_cabeca, resfriado, falta_de_ar, dor_de_garganta, 
    coriza, vomito, y2, y3, y4, y5, y6, y7, y8, y9, y10, y11) {
    let y2_ = 0
    let y3_ = 0
    let y4_ = 0
    let y5_ = 0
    let y6_ = 0
    let y7_ = 0
    let y8_ = 0 
    let y9_ = 0
    let y10_ = 0
    let y11_ = 0

    if (covid) {
      if (gripe)
        y2_ = y2 * media_covid['gripe']
      if (febre)
        y3_ = y3 * media_covid['febre']
      if (tosse)
        y4_ = y4 * media_covid['tosse']
      if (diarreia)
        y5_ = y5 * media_covid['diarreia']
      if (dor_de_cabeca)
        y6_ = y6 * media_covid['dor_de_cabeca']
      if (resfriado)
        y7_ = y7 * media_covid['resfriado']
      if (falta_de_ar)
        y8_ = y8 * media_covid['falta_de_ar']
      if (dor_de_garganta)
        y9_ = y9 * media_covid['dor_de_garganta']
      if (coriza)
        y10_ = y10 * media_covid['coriza']
      if (vomito)
        y11_ = y11 * media_covid['vomito']
    }
        
    else if (gripe) {
      y2_ = y2
      if (febre)
        y3_ = y3 * media_gripe['febre']
      if (tosse)
        y4_ = y4 * media_gripe['tosse']
      if (diarreia)
        y5_ = y5 * media_gripe['diarreia']
      if (dor_de_cabeca)
        y6_ = y6 * media_gripe['dor_de_cabeca']
      if (resfriado)
        y7_ = y7 * media_gripe['resfriado']
      if (falta_de_ar)
        y8_ = y8 * media_gripe['falta_de_ar']
      if (dor_de_garganta)
        y9_ = y9 * media_gripe['dor_de_garganta']
      if (coriza)
        y10_ = y10 * media_gripe['coriza']
      if (vomito)
        y11_ = y11 * media_gripe['vomito']
    }

    else if (febre) {
      y2_ = y2
      y3_ = y3
      if (tosse)
        y4_ = y4 * (media_gripe['tosse'] / media_gripe['febre'])
      if (diarreia)
        y5_ = y5 * (media_gripe['diarreia'] / media_gripe['febre'])
      if (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['febre'])
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['febre'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['febre'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['febre'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['febre'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['febre'])
    }
    else if (tosse)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      if  (diarreia)
        y5_ = y5 * (media_gripe['diarreia'] / media_gripe['tosse'])
      if  (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['tosse'])
      if  (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['tosse'])
      if  (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['tosse'])
      if  (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['tosse'])
      if  (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['tosse'])
      if  (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['tosse'])
    }
    else if (diarreia)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      if (dor_de_cabeca)
        y6_ = y6 * (media_gripe['dor_de_cabeca'] / media_gripe['diarreia'])
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['diarreia'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['diarreia'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['diarreia'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['diarreia'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['diarreia'])
    }

    else if (dor_de_cabeca)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      if (resfriado)
        y7_ = y7 * (media_gripe['resfriado'] / media_gripe['dor_de_cabeca'])
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['dor_de_cabeca'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['dor_de_cabeca'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['dor_de_cabeca'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['dor_de_cabeca'])
    }

    else if (resfriado)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      if (falta_de_ar)
        y8_ = y8 * (media_gripe['falta_de_ar'] / media_gripe['resfriado'])
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['resfriado'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['resfriado'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['resfriado'])
    }
    
    else if (falta_de_ar)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      if (dor_de_garganta)
        y9_ = y9 * (media_gripe['dor_de_garganta'] / media_gripe['falta_de_ar'])
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['falta_de_ar'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['falta_de_ar'])
    }

    else if (dor_de_garganta)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      if (coriza)
        y10_ = y10 * (media_gripe['coriza'] / media_gripe['dor_de_garganta'])
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['dor_de_garganta'])
    }
    
    else if (coriza)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      if (vomito)
        y11_ = y11 * (media_gripe['vomito'] / media_gripe['coriza'])
    }
    
    else if (vomito)
    {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }
   
    else {
      y2_ = y2
      y3_ = y3
      y4_ = y4
      y5_ = y5
      y6_ = y6
      y7_ = y7
      y8_ = y8
      y9_ = y9
      y10_ = y10
      y11_ = y11
    }

    return {y2_, y3_, y4_, y5_, y6_, y7_, y8_, y9_, y10_, y11_}
  }

  const calculateMovingAverage = (data, period) => {
    const dates = Object.keys(data);
    if (dates.length < period) return [];
    let movingAverages = [];
    for (let i = 0; i <= dates.length - period; i++) {
      let sum = 0;
      for (let j = 0; j < period; j++) {
        sum += data[dates[i + j]];
      }
      let average = sum / period;
      movingAverages.push({ data: dates[i + period - 1], total:average });
    }
  
    return movingAverages;
  };



  /*
  const calculateMovingAverage = (data, period) => {
    if (data.length < period) return [];
  
    let movingAverages = [];
    for (let i = 0; i <= data.length - period; i++) {
      let sum = 0;
      for (let j = 0; j < period; j++) {
        sum += data[i + j].total;
      }
      let average = sum / period;
      movingAverages.push({ data: data[i + period - 1].data, total: average });
    }
  
    return movingAverages;
  };*/

  const validarEstrutura = (objeto) => {
    // Regex para validar o formato da data
    const regexData = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  
    for (let chave in objeto) {
      if (!regexData.test(chave)) {
        console.error(`Chave inválida: ${chave}`);
        return false;
      }
  
      const valor = objeto[chave];
      if (typeof valor !== 'number') {
        console.error(`Valor inválido na chave ${chave}: ${valor}`);
        return false;
      }
    }
  
    return true;
  };

  useEffect(() => {    
    
      
        

        /*let exibe0 = !getOcultar(prioridade.current[0]["nome"]) ? prioridade.current[0]["nome"] : "";
        let exibe1 = !getOcultar(prioridade.current[1]["nome"]) ? prioridade.current[1]["nome"] : "";
        let exibe2 = !getOcultar(prioridade.current[2]["nome"]) ? prioridade.current[2]["nome"] : "";
        let exibe3 = !getOcultar(prioridade.current[3]["nome"]) ? prioridade.current[3]["nome"] : "";
        let exibe4 = !getOcultar(prioridade.current[4]["nome"]) ? prioridade.current[4]["nome"] : "";
        let exibe5 = !getOcultar(prioridade.current[5]["nome"]) ? prioridade.current[5]["nome"] : "";
        let exibe6 = !getOcultar(prioridade.current[6]["nome"]) ? prioridade.current[6]["nome"] : "";
        let exibe7 = !getOcultar(prioridade.current[7]["nome"]) ? prioridade.current[7]["nome"] : "";
        let exibe8 = !getOcultar(prioridade.current[8]["nome"]) ? prioridade.current[8]["nome"] : "";
        let exibe9 = !getOcultar(prioridade.current[9]["nome"]) ? prioridade.current[9]["nome"] : "";
        let exibe10 = !getOcultar(prioridade.current[10]["nome"]) ? prioridade.current[10]["nome"] : "";


        atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10]);*/
 
        carregaDadosMunicipio(cod_ibge);
        
        /*atualizaGraficoRelativo(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse,
          ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);*/
      
     /*atualizaGrafico(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
      ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);*/
  }, [minIdxDate, maxIdxDate, ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
    ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito]);

  useEffect(() => {
    if(dadosCovid.current.length > 0 && prioridade.current.length > 0){
      let exibe0 = !getOcultar(prioridade.current[0]["nome"]) ? prioridade.current[0]["nome"] : "";
      let exibe1 = !getOcultar(prioridade.current[1]["nome"]) ? prioridade.current[1]["nome"] : "";
      let exibe2 = !getOcultar(prioridade.current[2]["nome"]) ? prioridade.current[2]["nome"] : "";
      let exibe3 = !getOcultar(prioridade.current[3]["nome"]) ? prioridade.current[3]["nome"] : "";
      let exibe4 = !getOcultar(prioridade.current[4]["nome"]) ? prioridade.current[4]["nome"] : "";
      let exibe5 = !getOcultar(prioridade.current[5]["nome"]) ? prioridade.current[5]["nome"] : "";
      let exibe6 = !getOcultar(prioridade.current[6]["nome"]) ? prioridade.current[6]["nome"] : "";
      let exibe7 = !getOcultar(prioridade.current[7]["nome"]) ? prioridade.current[7]["nome"] : "";
      let exibe8 = !getOcultar(prioridade.current[8]["nome"]) ? prioridade.current[8]["nome"] : "";
      let exibe9 = !getOcultar(prioridade.current[9]["nome"]) ? prioridade.current[9]["nome"] : "";
      let exibe10 = !getOcultar(prioridade.current[10]["nome"]) ? prioridade.current[10]["nome"] : "";

      //atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10]);
      /*atualizaGraficoRelativo(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse,
        ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);*/
    }
   /* atualizaGrafico(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
      ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);*/
  
    if(isAllSelect(estadoSelecionado)){
      setEstadoSelecionado(t("dashboard.todos"));
    }

    if(isAllSelect(municipioSelecionado)){
      setMunicipioSelecionado(t("dashboard.todos"));
    }


    cidades_labels[0] = {label:t("dashboard.todos")};

    if(listaMunicipios.length === 1){
      setListaMunicipios(getCidades());
    }

    defaultPropsMunicipio = {
      options: cidades_labels,
      getOptionLabel: (option) => option.label,
    };

    setListaVariavelMediaMovel([
      {"sinal": "1", "nome": t("dashboard.variaveisGoogle.coriza")},
      {"sinal": "2", "nome": t("dashboard.variaveisGoogle.covid")},
      {"sinal": "3", "nome": t("dashboard.variaveisGoogle.diarreia")},
      {"sinal": "4", "nome": t("dashboard.variaveisGoogle.dor_de_cabeca")},
      {"sinal": "5", "nome": t("dashboard.variaveisGoogle.dor_de_garganta")},
      {"sinal": "6", "nome": t("dashboard.variaveisGoogle.falta_de_ar")},
      {"sinal": "7", "nome": t("dashboard.variaveisGoogle.febre")},
      {"sinal": "8", "nome": t("dashboard.variaveisGoogle.gripe")},
      {"sinal": "9", "nome": t("dashboard.variaveisGoogle.resfriado")},
      {"sinal": "10", "nome": t("dashboard.variaveisGoogle.tosse")},
      {"sinal": "11", "nome": t("dashboard.variaveisGoogle.vomito")},
    ]);

    setlistaMediaMovel([
      {"sinal": "1", "nome": "1 " + t("dashboard.semana")},
      {"sinal": "2", "nome": "2 " + t("dashboard.semanas")},
      {"sinal": "3", "nome": "3 " + t("dashboard.semanas")},
      {"sinal": "4", "nome": "4 " + t("dashboard.semanas")},
    ]);
  
  }, [language]);

  const atualizaMediaMovel = () => {
    dadosCovidMediaMovel.current = dadosCovid.current;
    dadosDiarreiaMediaMovel.current = dadosDiarreia.current;
    dadosDorDeCabecaMediaMovel.current = dadosDorDeCabeca.current;
    dadosDorDeGargantaMediaMovel.current = dadosDorDeGarganta.current;
    dadosFaltaDeArMediaMovel.current = dadosFaltaDeAr.current;
    dadosFebreMediaMovel.current = dadosFebre.current;
    dadosGripeMediaMovel.current = dadosGripe.current;
    dadosResfriadoMediaMovel.current = dadosResfriado.current;
    dadosTosseMediaMovel.current = dadosTosse.current;
    dadosVomitoMediaMovel.current = dadosVomito.current;
    dadosGripalMediaMovel.current = dadosGripal.current;
    dadosCorizaMediaMovel.current = dadosCoriza.current;
    
    //crie uma variavel temporaria para converter mediaMovelSelecionada para int
    let mediaMovelSelecionadaTemp = parseInt(mediaMovelSelecionada);

    dadosCovidMediaMovel.current = calculateMovingAverage(dadosCovidMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosDiarreiaMediaMovel.current = calculateMovingAverage(dadosDiarreiaMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosDorDeCabecaMediaMovel.current = calculateMovingAverage(dadosDorDeCabecaMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosDorDeGargantaMediaMovel.current = calculateMovingAverage(dadosDorDeGargantaMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosFaltaDeArMediaMovel.current = calculateMovingAverage(dadosFaltaDeArMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosFebreMediaMovel.current = calculateMovingAverage(dadosFebreMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosGripeMediaMovel.current = calculateMovingAverage(dadosGripeMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosResfriadoMediaMovel.current = calculateMovingAverage(dadosResfriadoMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosTosseMediaMovel.current = calculateMovingAverage(dadosTosseMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosVomitoMediaMovel.current = calculateMovingAverage(dadosVomitoMediaMovel.current, mediaMovelSelecionadaTemp);
    dadosCorizaMediaMovel.current = calculateMovingAverage(dadosCorizaMediaMovel.current, mediaMovelSelecionadaTemp);

    let maiorValor = 0;

    for (let key = 0; key < dadosCovidMediaMovel.current.length; key++) {
      if(dadosCovidMediaMovel.current[key]["data"] >= datas[minIdxDate] && dadosCovidMediaMovel.current[key]["data"] <= datas[maxIdxDate]){
        switch (variavelMediaMovelSelecionada) {
          case "1":
            if(dadosCorizaMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosCorizaMediaMovel.current[key]["total"];
            break;
          case "2":
            if(dadosCovidMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosCovidMediaMovel.current[key]["total"];
            break;
          case "3":
            if(dadosDiarreiaMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosDiarreiaMediaMovel.current[key]["total"];
            break;
          case "4":
            if(dadosDorDeCabecaMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosDorDeCabecaMediaMovel.current[key]["total"];
            break;
          case "5":
            if(dadosDorDeGargantaMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosDorDeGargantaMediaMovel.current[key]["total"];
            break;
          case "6":
            if(dadosFaltaDeArMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosFaltaDeArMediaMovel.current[key]["total"];
            break;
          case "7":
            if(dadosFebreMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosFebreMediaMovel.current[key]["total"];
            break;
          case "8":
            if(dadosGripeMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosGripeMediaMovel.current[key]["total"];
            break;
          case "9":
            if(dadosResfriadoMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosResfriadoMediaMovel.current[key]["total"];
            break;
          case "10":
            if(dadosTosseMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosTosseMediaMovel.current[key]["total"];
            break;
          case "11":
            if(dadosVomitoMediaMovel.current[key]["total"] > maiorValor)
              maiorValor = dadosVomitoMediaMovel.current[key]["total"];  
            break;
          default:
            break;
        }  
      }       
    };
    
    let multiplicador = maiorValor == 0 ? 1 : 100 / maiorValor;

    //aplique o multiplicador para todos os valores
    for (let key = 0; key < dadosCorizaMediaMovel.current.length; key++) {
      dadosCorizaMediaMovel.current[key]['total'] = dadosCorizaMediaMovel.current[key]['total'] * multiplicador;
      dadosCovidMediaMovel.current[key]['total'] = dadosCovidMediaMovel.current[key]['total'] * multiplicador;
      dadosDiarreiaMediaMovel.current[key]['total'] = dadosDiarreiaMediaMovel.current[key]['total'] * multiplicador;
      dadosDorDeCabecaMediaMovel.current[key]['total'] = dadosDorDeCabecaMediaMovel.current[key]['total'] * multiplicador;
      dadosDorDeGargantaMediaMovel.current[key]['total'] = dadosDorDeGargantaMediaMovel.current[key]['total'] * multiplicador;
      dadosFaltaDeArMediaMovel.current[key]['total'] = dadosFaltaDeArMediaMovel.current[key]['total'] * multiplicador;
      dadosFebreMediaMovel.current[key]['total'] = dadosFebreMediaMovel.current[key]['total'] * multiplicador;
      dadosGripeMediaMovel.current[key]['total'] = dadosGripeMediaMovel.current[key]['total'] * multiplicador;
      dadosResfriadoMediaMovel.current[key]['total'] = dadosResfriadoMediaMovel.current[key]['total'] * multiplicador;
      dadosTosseMediaMovel.current[key]['total'] = dadosTosseMediaMovel.current[key]['total'] * multiplicador;
      dadosVomitoMediaMovel.current[key]['total'] = dadosVomitoMediaMovel.current[key]['total'] * multiplicador;
    };

    dadosCorizaMediaMovel.current = Object.fromEntries(
      dadosCorizaMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosCovidMediaMovel.current = Object.fromEntries(
      dadosCovidMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosDiarreiaMediaMovel.current = Object.fromEntries(
      dadosDiarreiaMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosDorDeCabecaMediaMovel.current = Object.fromEntries(
      dadosDorDeCabecaMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosDorDeGargantaMediaMovel.current = Object.fromEntries(
      dadosDorDeGargantaMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosFaltaDeArMediaMovel.current = Object.fromEntries(
      dadosFaltaDeArMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosFebreMediaMovel.current = Object.fromEntries(
      dadosFebreMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosGripeMediaMovel.current = Object.fromEntries(
      dadosGripeMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosResfriadoMediaMovel.current = Object.fromEntries(
      dadosResfriadoMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosTosseMediaMovel.current = Object.fromEntries(
      dadosTosseMediaMovel.current.map(({data, total}) => [data, total])
    );

    dadosVomitoMediaMovel.current = Object.fromEntries(
      dadosVomitoMediaMovel.current.map(({data, total}) => [data, total])
    );

    setDadosMediaAtendimentoTodos(
      getDadosMediaGraficoTodos(t("dashboard.graficos.titulo_atendimento_gripais"), dadosGripalMediaMovel.current, t("dashboard.variaveisGoogle.coriza"), dadosCorizaMediaMovel.current, t("dashboard.variaveisGoogle.covid"), dadosCovidMediaMovel.current, t("dashboard.variaveisGoogle.diarreia"), dadosDiarreiaMediaMovel.current, t("dashboard.variaveisGoogle.dor_de_cabeca"), dadosDorDeCabecaMediaMovel.current, t("dashboard.variaveisGoogle.dor_de_garganta"), dadosDorDeGargantaMediaMovel.current, t("dashboard.variaveisGoogle.falta_de_ar"), dadosFaltaDeArMediaMovel.current, t("dashboard.variaveisGoogle.febre"), dadosFebreMediaMovel.current, t("dashboard.variaveisGoogle.gripe"), dadosGripeMediaMovel.current, t("dashboard.variaveisGoogle.resfriado"), dadosResfriadoMediaMovel.current, t("dashboard.variaveisGoogle.tosse"), dadosTosseMediaMovel.current, t("dashboard.variaveisGoogle.vomito"), dadosVomitoMediaMovel.current, false)
    );
  }

  const carregaDadosMunicipio = (co_ibge) => {
    let tempDatas = [];
    let co_ibge_estado = co_ibge.substring(0,2);

    let url_prioridade = isAllSelect(co_ibge) ? "Dados/google_trends/prioridade_todos.json" : "Dados/google_trends/estados/prioridade_" + co_ibge_estado + ".json";

    fetch(url_prioridade, {
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
    })
    .then(function (response) {
        return response.json();
    })
    .then(function (myJson_) {
      prioridade.current = myJson_;
     
      let url_dados = "";
      if(co_ibge.length === 2){
        url_dados = "Dados/google_trends/estados/dados_" + co_ibge + ".json";
      } else{
        url_dados = isAllSelect(co_ibge) ? "Dados/google_trends/dados_todos.json" : "Dados/google_trends/municipios/dados_" + co_ibge + ".json";
      }

      fetch(url_dados
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                  dadosCoriza.current = [];
                  dadosCovid.current = [];
                  dadosDiarreia.current = [];
                  dadosDorDeCabeca.current = [];
                  dadosDorDeGarganta.current = [];
                  dadosFaltaDeAr.current = [];
                  dadosFebre.current = [];
                  dadosGripe.current = [];
                  dadosResfriado.current = [];
                  dadosTosse.current = [];
                  dadosVomito.current = [];
                  dadosGripal.current = [];
            
                  dadosCorizaMediaMovel.current = [];
                  dadosCovidMediaMovel.current = [];
                  dadosDiarreiaMediaMovel.current = [];
                  dadosDorDeCabecaMediaMovel.current = [];
                  dadosDorDeGargantaMediaMovel.current = [];
                  dadosFaltaDeArMediaMovel.current = [];
                  dadosFebreMediaMovel.current = [];
                  dadosGripeMediaMovel.current = [];
                  dadosResfriadoMediaMovel.current = [];
                  dadosTosseMediaMovel.current = [];
                  dadosVomitoMediaMovel.current = [];
                  dadosGripalMediaMovel.current = [];

                  //console.log(myJson);
                  
                  myJson.atendimentos.sort(compararPorData);

                    let tempSemanas = [];
                    for(let i = 0; i < myJson["atendimentos"].length; i++){
                      const element = myJson["atendimentos"][i];

                      element["data"] = element["data"];
                      //tempDatas.push(element["data"]);

                      tempSemanas.push((element["semana"]));

                      //console.log(element["data"]);

                      var obj = {};
                      obj['data'] = element["data"];
                      obj['total'] = element["t_g_coriza"];
                      dadosCoriza.current.push(obj);
                      dadosCorizaMediaMovel.current.push(obj);

                      var obj2 = {};
                      obj2['data'] = element["data"];
                      obj2['total'] = element["t_g_covid"];
                      dadosCovid.current.push(obj2);
                      dadosCovidMediaMovel.current.push(obj2);

                      var obj3 = {};
                      obj3['data'] = element["data"];
                      obj3['total'] = element["t_g_diarreia"];
                      dadosDiarreia.current.push(obj3);
                      dadosDiarreiaMediaMovel.current.push(obj3);

                      var obj4 = {};
                      obj4['data'] = element["data"];
                      obj4['total'] = element["t_g_dor_de_cabeca"];
                      dadosDorDeCabeca.current.push(obj4);
                      dadosDorDeCabecaMediaMovel.current.push(obj4);

                      var obj5 = {};
                      obj5['data'] = element["data"];
                      obj5['total'] = element["t_g_dor_de_garganta"];
                      dadosDorDeGarganta.current.push(obj5);
                      dadosDorDeGargantaMediaMovel.current.push(obj5);

                      var obj6 = {};
                      obj6['data'] = element["data"];
                      obj6['total'] = element["t_g_falta_de_ar"];
                      dadosFaltaDeAr.current.push(obj6);
                      dadosFaltaDeArMediaMovel.current.push(obj6);

                      var obj7 = {};
                      obj7['data'] = element["data"];
                      obj7['total'] = element["t_g_febre"];
                      dadosFebre.current.push(obj7);
                      dadosFebreMediaMovel.current.push(obj7);

                      var obj8 = {};
                      obj8['data'] = element["data"];
                      obj8['total'] = element["t_g_gripe"];
                      dadosGripe.current.push(obj8);
                      dadosGripeMediaMovel.current.push(obj8);

                      var obj9 = {};
                      obj9['data'] = element["data"];
                      obj9['total'] = element["t_g_resfriado"];
                      dadosResfriado.current.push(obj9);
                      dadosResfriadoMediaMovel.current.push(obj9);

                      var obj10 = {};
                      obj10['data'] = element["data"];
                      obj10['total'] = element["t_g_tosse"];
                      dadosTosse.current.push(obj10);
                      dadosTosseMediaMovel.current.push(obj10);
                      

                      var obj11 = {};
                      obj11['data'] = element["data"];
                      obj11['total'] = element["t_g_vomito"];
                      dadosVomito.current.push(obj11);
                      dadosVomitoMediaMovel.current.push(obj11);
                      

                      var obj12 = {};
                      obj12['data'] = element["data"];
                      obj12['total'] = element["total_gripal"];
                      dadosGripal.current.push(obj12);
                      dadosGripalMediaMovel.current.push(obj12);
                       
                    }

                    //setDatas(tempDatas);

                    /*
                    //converte datas para semanas
                    let tempSemanas = [];
                    for (let index = 0; index < tempDatas.length; index++) {
                      const element = tempDatas[index];
                      tempSemanas.push(moment(element).isoWeek());
                    }*/
                    setSemanas(tempSemanas);
                    /*
                    let tempInicioSemana = [];
                    let tempFimSemana = [];
                    for (let index = 0; index < tempDatas.length; index++) {
                      const element = tempDatas[index];
                      tempInicioSemana.push(moment(element).startOf('isoWeek').format('YYYY-MM-DD'));
                      tempFimSemana.push(moment(element).endOf('isoWeek').format('YYYY-MM-DD'));
                    }

                    setInicioSemanas(tempInicioSemana);
                    setFimSemanas(tempFimSemana);
                    */

                    dadosCoriza.current = Object.fromEntries(
                      dadosCoriza.current.map(({data, total}) => [data, total])
                    );

                    dadosCovid.current = Object.fromEntries(
                      dadosCovid.current.map(({data, total}) => [data, total])
                    );

                    dadosDiarreia.current = Object.fromEntries(
                      dadosDiarreia.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeCabeca.current = Object.fromEntries(
                      dadosDorDeCabeca.current.map(({data, total}) => [data, total])
                    );

                    dadosDorDeGarganta.current = Object.fromEntries(
                      dadosDorDeGarganta.current.map(({data, total}) => [data, total])
                    );

                    dadosFaltaDeAr.current = Object.fromEntries(
                      dadosFaltaDeAr.current.map(({data, total}) => [data, total])
                    );

                    dadosFebre.current = Object.fromEntries(
                      dadosFebre.current.map(({data, total}) => [data, total])
                    );

                    dadosGripe.current = Object.fromEntries(
                      dadosGripe.current.map(({data, total}) => [data, total])
                    );

                    dadosResfriado.current = Object.fromEntries(
                      dadosResfriado.current.map(({data, total}) => [data, total])
                    );

                    dadosTosse.current = Object.fromEntries(
                      dadosTosse.current.map(({data, total}) => [data, total])
                    );

                    dadosVomito.current = Object.fromEntries(
                      dadosVomito.current.map(({data, total}) => [data, total])
                    );

                    dadosGripal.current = Object.fromEntries(
                      dadosGripal.current.map(({data, total}) => [data, total])
                    );                    

                    let exibe0 = !getOcultar(prioridade.current[0]["nome"]) ? prioridade.current[0]["nome"] : "";
                    let exibe1 = !getOcultar(prioridade.current[1]["nome"]) ? prioridade.current[1]["nome"] : "";
                    let exibe2 = !getOcultar(prioridade.current[2]["nome"]) ? prioridade.current[2]["nome"] : "";
                    let exibe3 = !getOcultar(prioridade.current[3]["nome"]) ? prioridade.current[3]["nome"] : "";
                    let exibe4 = !getOcultar(prioridade.current[4]["nome"]) ? prioridade.current[4]["nome"] : "";
                    let exibe5 = !getOcultar(prioridade.current[5]["nome"]) ? prioridade.current[5]["nome"] : "";
                    let exibe6 = !getOcultar(prioridade.current[6]["nome"]) ? prioridade.current[6]["nome"] : "";
                    let exibe7 = !getOcultar(prioridade.current[7]["nome"]) ? prioridade.current[7]["nome"] : "";
                    let exibe8 = !getOcultar(prioridade.current[8]["nome"]) ? prioridade.current[8]["nome"] : "";
                    let exibe9 = !getOcultar(prioridade.current[9]["nome"]) ? prioridade.current[9]["nome"] : "";
                    let exibe10 = !getOcultar(prioridade.current[10]["nome"]) ? prioridade.current[10]["nome"] : "";

                    /*console.log(prioridade[0]["nome"], prioridade[1]["nome"], prioridade[2]["nome"], prioridade[3]["nome"], prioridade[4]["nome"], prioridade[5]["nome"], prioridade[6]["nome"], prioridade[7]["nome"], prioridade[8]["nome"], prioridade[9]["nome"], prioridade[10]["nome"])
                    console.log(exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10);*/

                    //console.log(dadosCoriza.current, dadosCovid.current, dadosDiarreia.current, dadosDorDeCabeca.current, dadosDorDeGarganta.current, dadosFaltaDeAr.current, dadosFebre.current, dadosGripe.current, dadosResfriado.current, dadosTosse.current, dadosVomito.current, dadosGripal.current);

                    atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10], tempDatas);
                    
                    atualizaMediaMovel();
                  });
            });
  }

  const [iniciou, setIniciou] = useState(false);

  const comparaDataInicio = (data1, data2) => {        
    if(data1 === null || data2 === null)
        return false;
    return data1 >= data2;
  }

  const getIdxDataInicio = (datas_local) => {
    var idx = 0;
    for (let idx_data = 0; idx_data < datas_local.length; idx_data++) {
        if (comparaDataInicio(new Date(datas_local[idx_data]), dataInicial.current)) {
            idx = idx_data;
            break;
        }
    }
    return idx;
  }

  const getLastYear = (dt) => {
    let dt_local = new Date(dt);
    dt_local.setFullYear(dt_local.getFullYear() - Number(1));
    return dt_local;
}

const calculateWeekStartEnd = (week, year) => {
  const firstDayOfYear = new Date(year, 0, 1);
  const firstWeekStart = new Date(firstDayOfYear);
  firstWeekStart.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1);
  const weekStartDate = new Date(firstWeekStart);
  weekStartDate.setDate(firstWeekStart.getDate() + (week - 1) * 7);
  const weekEndDate = new Date(weekStartDate);
  weekEndDate.setDate(weekStartDate.getDate() + 6);

  return [weekStartDate.toLocaleDateString(), weekEndDate.toLocaleDateString()];
};

  function getOcultar(termo){
    if(termo === "covid")
      return ocultaCovid;
    if(termo === "gripe")
      return ocultaGripe;
    if(termo === "febre")
      return ocultaFebre;
    if(termo === "tosse")
      return ocultaTosse;
    if(termo === "diarreia")
      return ocultaDiarreia;
    if(termo === "dor_de_cabeca")
      return ocultaDorDeCabeca;
    if(termo === "resfriado")
      return ocultaResfriado;
    if(termo === "falta_de_ar")
      return ocultaFaltaDeAr;
    if(termo === "dor_de_garganta")
      return ocultaDorDeGarganta;
    if(termo === "coriza")
      return ocultaCoriza;
    if(termo === "vomito")
      return ocultaVomito;
  };

  function getOcultarTemp(termo, ocultarCovid, ocultarGripe, ocultarFebre, ocultarTosse, ocultarDiarreia, ocultarDorDeCabeca, ocultarResfriado, ocultarFaltaDeAr, ocultarDorDeGarganta, ocultarCoriza, ocultarVomito){
    if(termo === "covid")
      return ocultaCovid;
    if(termo === "gripe")
      return ocultaGripe;
    if(termo === "febre")
      return ocultaFebre;
    if(termo === "tosse")
      return ocultaTosse;
    if(termo === "diarreia")
      return ocultaDiarreia;
    if(termo === "dor_de_cabeca")
      return ocultaDorDeCabeca;
    if(termo === "resfriado")
      return ocultaResfriado;
    if(termo === "falta_de_ar")
      return ocultaFaltaDeAr;
    if(termo === "dor_de_garganta")
      return ocultaDorDeGarganta;
    if(termo === "coriza")
      return ocultaCoriza;
    if(termo === "vomito")
      return ocultaVomito;
  };

  function getDados(idx_termo){
    if(getIndiceTermo("covid", prioridade.current) === idx_termo){
      //console.log(idx_termo, "covid");
      return dadosCovid.current;
    }
    if(getIndiceTermo("gripe", prioridade.current) === idx_termo){
      //console.log(idx_termo, "gripe");
      return dadosGripe.current;
    }
    if(getIndiceTermo("febre", prioridade.current) === idx_termo){
      //console.log(idx_termo, "febre");
      return dadosFebre.current;
    }
    if(getIndiceTermo("tosse", prioridade.current) === idx_termo){
      //console.log(idx_termo, "tosse");
      return dadosTosse.current;
    }
    if(getIndiceTermo("diarreia", prioridade.current) === idx_termo){
      //console.log(idx_termo, "diarreia");
      return dadosDiarreia.current;
    }
    if(getIndiceTermo("dor_de_cabeca", prioridade.current) === idx_termo){
      //console.log(idx_termo, "dor_de_cabeca");
      return dadosDorDeCabeca.current;
    }
    if(getIndiceTermo("resfriado", prioridade.current) === idx_termo){
      //console.log(idx_termo, "resfriado");
      return dadosResfriado.current;
    }
    if(getIndiceTermo("falta_de_ar", prioridade.current) === idx_termo){
      //console.log(idx_termo, "falta_de_ar");
      return dadosFaltaDeAr.current;
    }
    if(getIndiceTermo("dor_de_garganta", prioridade.current) === idx_termo){
      //console.log(idx_termo, "dor_de_garganta");
      return dadosDorDeGarganta.current;
    }
    if(getIndiceTermo("coriza", prioridade.current) === idx_termo){
      //console.log(idx_termo, "coriza");
      return dadosCoriza.current;
    }
    if(getIndiceTermo("vomito", prioridade.current) === idx_termo){
      //console.log(idx_termo, "vomito");
      return dadosVomito.current;
    }
  };

  function getIndiceTermo (termo, termos){
    for (let index = 0; index < termos.length; index++) {
      const element = termos[index];
      if(element["nome"] === termo)
        return index;
    }
  };
   
  useEffect(() => {
    if(semanas.length > 0 && prioridade.length > 0){
      let dtInicial = datas[datas.length -1] ;
      dataInicial.current = getLastYear(dtInicial);

      let idxDataInicio = getIdxDataInicio(datas);
      let idxDataSelecionada = datas.length - 1;
      
      setMaxDateRangeInicial(inicioSemanas[idxDataSelecionada]);
      setMaxDateRangeFinal(fimSemanas[idxDataSelecionada]);
      
      setInicioSemanaSelecionada(inicioSemanas[idxDataSelecionada]);
      setFimSemanaSelecionada(fimSemanas[idxDataSelecionada]);
      setInicioSemanaSelecionadaRange(semanas[idxDataInicio]);
      setFimSemanaSelecionadaRange(semanas[idxDataSelecionada]);

      /*let exibe0 = !getOcultar(prioridade[0]["nome"]) ? prioridade[0]["nome"] : "";
      let exibe1 = !getOcultar(prioridade[1]["nome"]) ? prioridade[1]["nome"] : "";
      let exibe2 = !getOcultar(prioridade[2]["nome"]) ? prioridade[2]["nome"] : "";
      let exibe3 = !getOcultar(prioridade[3]["nome"]) ? prioridade[3]["nome"] : "";
      let exibe4 = !getOcultar(prioridade[4]["nome"]) ? prioridade[4]["nome"] : "";
      let exibe5 = !getOcultar(prioridade[5]["nome"]) ? prioridade[5]["nome"] : "";
      let exibe6 = !getOcultar(prioridade[6]["nome"]) ? prioridade[6]["nome"] : "";
      let exibe7 = !getOcultar(prioridade[7]["nome"]) ? prioridade[7]["nome"] : "";
      let exibe8 = !getOcultar(prioridade[8]["nome"]) ? prioridade[8]["nome"] : "";
      let exibe9 = !getOcultar(prioridade[9]["nome"]) ? prioridade[9]["nome"] : "";
      let exibe10 = !getOcultar(prioridade[10]["nome"]) ? prioridade[10]["nome"] : "";

      atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10]);*/

      /*
      atualizaGrafico(ocultaCovid, ocultaGripe, ocultaFebre, ocultaTosse, 
        ocultaDiarreia, ocultaDorDeCabeca, ocultaResfriado, ocultaFaltaDeAr, ocultaDorDeGarganta, ocultaCoriza, ocultaVomito);
      */
      
    }
  }, [semanas]);

  function getFirstDayOfWeek(ano, semana) {
    // Início do ano
    const inicioAno = startOfYear(new Date(ano, 0, 1));
  
    // A primeira semana do ano pode não começar na segunda-feira
    // Portanto, usamos startOfWeek para ajustar para o primeiro dia da semana desejado (default: Sunday)
    const primeiroDiaSemana = startOfWeek(inicioAno, { weekStartsOn: 0 }); // 0 = Domingo, 1 = Segunda-feira
  
    // Adiciona o número de semanas para encontrar o primeiro dia da semana desejada
    const dataInicialSemana = addDays(primeiroDiaSemana, (semana - 1) * 7);
  
    return dataInicialSemana;
  }

  function getLastDayOfWeek(ano, semana) {
    // Início do ano
    const inicioAno = startOfYear(new Date(ano, 0, 1));
  
    // A primeira semana do ano pode não começar na segunda-feira
    // Portanto, usamos startOfWeek para ajustar para o primeiro dia da semana desejado (default: Sunday)
    const primeiroDiaSemana = startOfWeek(inicioAno, { weekStartsOn: 0 }); // 0 = Domingo, 1 = Segunda-feira
  
    // Adiciona o número de semanas para encontrar o primeiro dia da semana desejada
    const dataInicialSemana = addDays(primeiroDiaSemana, (semana - 1) * 7);
  
    // Calcula o último dia da semana
    const ultimoDiaSemana = endOfWeek(dataInicialSemana, { weekStartsOn: 0 }); // 0 = Domingo, 1 = Segunda-feira
  
    return ultimoDiaSemana;
  }

  function carregaDatas(){
    fetch("Dados/google_trends/dados_todos.json"
      , {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
      }
    )
      .then(function (response) {
          //Limpa pontos anteriores
          return response.json();
      })
      .then(function (myJson) {
        let tempDatas = [];
        myJson.atendimentos.sort(compararPorData);
        let tempSemanas = [];

        let tempInicioSemana = [];
        let tempFimSemana = [];

          for(let i = 0; i < myJson["atendimentos"].length; i++){
            const element = myJson["atendimentos"][i];

            tempDatas.push(element["data"]);

            tempSemanas.push(element["semana"]/*moment(element).isoWeek()*/);

            tempInicioSemana.push(format(getFirstDayOfWeek(new Date(element["data"]).getFullYear(), element["semana"]), 'yyyy/MM/dd'));
            tempFimSemana.push(format(getLastDayOfWeek(new Date(element["data"]).getFullYear(), element["semana"]), 'yyyy/MM/dd'));
          }

          setDatas(tempDatas);

          //converte datas para semanas
          
          /*for (let index = 0; index < tempDatas.length; index++) {
            const element = tempDatas[index];
          }*/
          setSemanas(tempSemanas);

          /*for (let index = 0; index < tempDatas.length; index++) {
            const element = tempDatas[index];
            tempInicioSemana.push(moment(element).startOf('isoWeek').format('YYYY-MM-DD'));
            tempFimSemana.push(moment(element).endOf('isoWeek').format('YYYY-MM-DD'));

          }*/

          setInicioSemanas(tempInicioSemana);
          setFimSemanas(tempFimSemana);

          let dtInicial = tempDatas[tempDatas.length -1] ;
          dataInicial.current = getLastYear(dtInicial);
    
          let idxDataInicio = getIdxDataInicio(tempDatas);
          let idxDataSelecionada = tempDatas.length - 1;
          
          setMinDate(tempDatas[idxDataInicio]);
          setMinDateRangeInicial(tempInicioSemana[idxDataInicio]);
          setMinDateRangeFinal(tempFimSemana[idxDataInicio]);
          setMinIdxDate(idxDataInicio);
          setMaxDate(tempDatas[idxDataSelecionada]);
          setMaxIdxDate(idxDataSelecionada);

          setIniciou(true);
        }); 
  }

  useEffect(() => {
    carregaDatas();
  }, []);

  useEffect(() => {
    if(iniciou){

      let dtInicial = datas[datas.length -1] ;
      dataInicial.current = getLastYear(dtInicial);

      let idxDataInicio = getIdxDataInicio(datas);
      let idxDataSelecionada = datas.length - 1;

      setMinDate(datas[idxDataInicio]);
      setMinDateRangeInicial(inicioSemanas[idxDataInicio]);
      setMinDateRangeFinal(fimSemanas[idxDataInicio]);
      setMinIdxDate(idxDataInicio);
      setMaxDate(datas[idxDataSelecionada]);
      setMaxIdxDate(idxDataSelecionada);
      
      setMaxDateRangeInicial(inicioSemanas[idxDataSelecionada]);
      setMaxDateRangeFinal(fimSemanas[idxDataSelecionada]);
      /*atualizaRangeGraficos(idxDataInicio, "min", false);
      atualizaRangeGraficos(idxDataSelecionada, "max", false);*/
      setValueRangeData([idxDataInicio,idxDataSelecionada]);
      //setSemanaSelecionada(semanas[idxDataSelecionada]);
      setInicioSemanaSelecionada(inicioSemanas[idxDataSelecionada]);
      setFimSemanaSelecionada(fimSemanas[idxDataSelecionada]);
      setInicioSemanaSelecionadaRange(semanas[idxDataInicio]);
      setFimSemanaSelecionadaRange(semanas[idxDataSelecionada]);

      let co_ibge =  t("dashboard.todos");
      carregaDadosMunicipio(co_ibge);
    }
  }, [iniciou]);

  var corLinhaMarcaoGrafico = ("rgba(94, 94, 94, 1)");

  function FormatarDoisDigitos(numero) {
    if(numero === null || numero === undefined)
        return "00";
    return numero.toString().padStart(2, '0');
  }

  const getWeekStartingSunday = (date) => {
    // Calcula o primeiro dia do ano e ajusta para começar no domingo
    const startOfYearDate = startOfYear(date);
    const startOfFirstWeek = new Date(startOfYearDate);
    startOfFirstWeek.setDate(
      startOfYearDate.getDate() - startOfYearDate.getDay()
    );
  
    // Calcula o número de dias desde o início da primeira semana
    const dayOfYear = differenceInCalendarDays(date, startOfFirstWeek);
  
    // Calcula a semana do ano, ajustando para começar no domingo
    return Math.floor(dayOfYear / 7) + 1;
  };

  function exibeMesAnoGrafico(index, values){

    const totalTicks = values.length;

    const date = moment(values[index].value);
    
    const week = getISOWeek(new Date(values[index].value), { weekStartsOn: 0 }) +1 ;

    //const week = getWeekStartingSunday(new Date(values[index].value));

    // retorne a semana

    // Sempre mostre o primeiro e o último tick
    /*if (index === 0 || index === totalTicks - 1) {
        return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
    }*/

    // Lógica para mostrar ticks intermediários
    let intermediateTicks = 10; // número de ticks intermediários desejados

    let totalWeekTicks = Math.floor(totalTicks / 7);

    if(totalWeekTicks < intermediateTicks)
        intermediateTicks = totalWeekTicks - 2;
        if(intermediateTicks < 0)
            intermediateTicks = 0;

    const stepSize = Math.floor(totalTicks / (intermediateTicks + 1));

    if (index % stepSize === 0) {
        return `${FormatarDoisDigitos(week)}-${date.year()}`;
    }

    return null; // Não mostra outros ticks
}

/*function triggerLegendClick(chart, datasetIndex) {
  console.log(chart);
  const legend = chart.current.legend;
  console.log(legend);

  const legendItem = legend.legendItems[datasetIndex];

  // Simula o evento de clique
  const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
  });

  legendItem.$context = {
      chart: chart,
      dataIndex: datasetIndex,
      datasetIndex: datasetIndex,
      index: datasetIndex,
      element: null
  };

  legendItem.$handlers.onClick(clickEvent, legendItem);
}*/
function atualizaGraficoRelativo(termos, tempData){
 /*let dadosGripalTemp = [];*/
       let dadosCorizaTemp = [];
       let dadosCovidTemp = [];
       let dadosDiarreiaTemp = [];
       let dadosDorDeCabecaTemp = [];
       let dadosDorDeGargantaTemp = [];
       let dadosFaltaDeArTemp = [];
       let dadosFebreTemp = [];
       let dadosGripeTemp = [];
       let dadosResfriadoTemp = [];
       let dadosTosseTemp = [];
       let dadosVomitoTemp = [];

       /*let dadosGripalTemp2023 = [];*/
       let dadosCorizaTempMediaMovel = [];
       let dadosCovidTempMediaMovel = [];
       let dadosDiarreiaTempMediaMovel = [];
       let dadosDorDeCabecaTempMediaMovel = [];
       let dadosDorDeGargantaTempMediaMovel = [];
       let dadosFaltaDeArTempMediaMovel = [];
       let dadosFebreTempMediaMovel = [];
       let dadosGripeTempMediaMovel = [];
       let dadosResfriadoTempMediaMovel = [];
       let dadosTosseTempMediaMovel = [];
       let dadosVomitoTempMediaMovel = [];

       let maiorValor = 0;

       let datasLocal = [];
       if(tempData && tempData.length > 0){
        datasLocal = tempData;
       } else {
        datasLocal = datas;
       }

       Object.keys(dadosCoriza.current).forEach((key) => {
        
         if(key >= datasLocal[minIdxDate] && key <= datasLocal[maxIdxDate]){

           let resultado = calculo_sintomas_relativos(termos, getDados(0)[key],
             getDados(1)[key], getDados(2)[key], getDados(3)[key], getDados(4)[key], 
             getDados(5)[key], getDados(6)[key], getDados(7)[key], 
             getDados(8)[key], getDados(9)[key], getDados(10)[key]);

           //console.log("passei aqui");
          // console.log(key, getDados(0), getDados(1), getDados(2), getDados(3), getDados(4), getDados(5), getDados(6), getDados(7), getDados(8), getDados(9), getDados(10), resultado);

           const dataReferencia = moment('2023-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
           const dataConvertida = moment(key, 'YYYY-MM-DD HH:mm:ss');

           var obj2 = {};
           obj2['data'] = key;
           let indice = getIndiceTermo("coriza", prioridade.current);
           let yCoriza = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null;
           //console.log('y'+(indice+1)+'_', yCoriza);
           obj2['total'] = yCoriza;/*dadosCoriza.current[key];*/
           dadosCorizaTemp.push(obj2);
           dadosCorizaTempMediaMovel.push(obj2);
           
           var obj3 = {};
           obj3['data'] = key;
           obj3['total'] = dadosCovid.current[key];
           dadosCovidTemp.push(obj3);
           dadosCovidTempMediaMovel.push(obj3);
           
           var obj4 = {};
           obj4['data'] = key;
           indice = getIndiceTermo("diarreia", prioridade.current);
           obj4['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosDiarreia.current[key];*/
           //console.log('y'+(indice+1)+'_');
           dadosDiarreiaTemp.push(obj4);
           dadosDiarreiaTempMediaMovel.push(obj4);
           
           var obj5 = {};
           obj5['data'] = key;
           indice = getIndiceTermo("dor_de_cabeca", prioridade.current);
           obj5['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosDorDeCabeca.current[key];*/
           //console.log('y'+(indice+1)+'_');
           dadosDorDeCabecaTemp.push(obj5);
           dadosDorDeCabecaTempMediaMovel.push(obj5);
           

           var obj6 = {};
           obj6['data'] = key;
           indice = getIndiceTermo("dor_de_garganta", prioridade.current);
           obj6['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosDorDeGarganta.current[key];*/
           //console.log('y'+(indice+1)+'_');  
           dadosDorDeGargantaTemp.push(obj6);
           dadosDorDeGargantaTempMediaMovel.push(obj6);
           

           var obj7 = {};
           obj7['data'] = key;
           indice = getIndiceTermo("falta_de_ar", prioridade.current);
           obj7['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosFaltaDeAr.current[key];*/
           dadosFaltaDeArTemp.push(obj7);
           dadosFaltaDeArTempMediaMovel.push(obj7);
           

           var obj8 = {};
           obj8['data'] = key;
           indice = getIndiceTermo("febre", prioridade.current);
           obj8['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosFebre.current[key];*/
           dadosFebreTemp.push(obj8);
           dadosFebreTempMediaMovel.push(obj8);
           

           var obj9 = {};
           obj9['data'] = key;
           indice = getIndiceTermo("gripe", prioridade.current);
           obj9['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosGripe.current[key];*/
           dadosGripeTemp.push(obj9);
           dadosGripeTempMediaMovel.push(obj9);
           

           var obj10 = {};
           obj10['data'] = key;
           indice = getIndiceTermo("resfriado", prioridade.current);
           obj10['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosResfriado.current[key]*/;
           dadosResfriadoTemp.push(obj10);
           dadosResfriadoTempMediaMovel.push(obj10);
           

           var obj11 = {};
           obj11['data'] = key;
           indice = getIndiceTermo("tosse", prioridade.current);
           obj11['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null;; /*dadosTosse.current[key];*/
           dadosTosseTemp.push(obj11);
           dadosTosseTempMediaMovel.push(obj11);
           

           var obj12 = {};
           obj12['data'] = key;
           indice = getIndiceTermo("vomito", prioridade.current);
           //console.log('y'+(indice+1)+'_', resultado['y'+(indice+1)+'_']);
           obj12['total'] = resultado['y'+(indice+1)+'_'] ? resultado['y'+(indice+1)+'_'] : null; /*dadosVomito.current[key];*/
           dadosVomitoTemp.push(obj12);
           dadosVomitoTempMediaMovel.push(obj12);
          
         }

       });

      let covid = getOcultar("covid");
      let gripe = getOcultar("gripe");
      let febre = getOcultar("febre");
      let tosse = getOcultar("tosse");
      let diarreia = getOcultar("diarreia");
      let dor_de_cabeca = getOcultar("dor_de_cabeca");
      let resfriado = getOcultar("resfriado");
      let falta_de_ar = getOcultar("falta_de_ar");
      let dor_de_garganta = getOcultar("dor_de_garganta");
      let coriza = getOcultar("coriza");
      let vomito = getOcultar("vomito");

       for (let key = 0; key < dadosCorizaTemp.length; key++) {
         if(!coriza && dadosCorizaTemp[key]["total"] > maiorValor)
           maiorValor = dadosCorizaTemp[key]["total"];
         if(!covid && dadosCovidTemp[key]["total"] > maiorValor)
           maiorValor = dadosCovidTemp[key]["total"];
         if(!diarreia && dadosDiarreiaTemp[key]["total"] > maiorValor)
           maiorValor = dadosDiarreiaTemp[key]["total"];
         if(!dor_de_cabeca && dadosDorDeCabecaTemp[key]["total"] > maiorValor)
           maiorValor = dadosDorDeCabecaTemp[key]["total"];
         if(!dor_de_garganta && dadosDorDeGargantaTemp[key]["total"] > maiorValor)
           maiorValor = dadosDorDeGargantaTemp[key]["total"];
         if(!falta_de_ar && dadosFaltaDeArTemp[key]["total"] > maiorValor)
           maiorValor = dadosFaltaDeArTemp[key]["total"];
         if(!febre && dadosFebreTemp[key]["total"] > maiorValor)
           maiorValor = dadosFebreTemp[key]["total"];
         if(!gripe && dadosGripeTemp[key]["total"] > maiorValor)
           maiorValor = dadosGripeTemp[key]["total"];
         if(!resfriado && dadosResfriadoTemp[key]["total"] > maiorValor)
           maiorValor = dadosResfriadoTemp[key]["total"];
         if(!tosse && dadosTosseTemp[key]["total"] > maiorValor)
           maiorValor = dadosTosseTemp[key]["total"];
         if(!vomito && dadosVomitoTemp[key]["total"] > maiorValor)
           maiorValor = dadosVomitoTemp[key]["total"];
         /*if(!coriza && dadosCorizaTemp[key]["total"] > maiorValor)
           maiorValor = dadosCorizaTemp[key]["total"]; */          
       };
       
       let multiplicador = maiorValor == 0 ? 1 : 100 / maiorValor;

       //aplique o multiplicador para todos os valores
       for (let key = 0; key < dadosCorizaTemp.length; key++) {
         dadosCorizaTemp[key]['total'] = dadosCorizaTemp[key]['total'] * multiplicador;
         dadosCovidTemp[key]['total'] = dadosCovidTemp[key]['total'] * multiplicador;
         dadosDiarreiaTemp[key]['total'] = dadosDiarreiaTemp[key]['total'] * multiplicador;
         dadosDorDeCabecaTemp[key]['total'] = dadosDorDeCabecaTemp[key]['total'] * multiplicador;
         dadosDorDeGargantaTemp[key]['total'] = dadosDorDeGargantaTemp[key]['total'] * multiplicador;
         dadosFaltaDeArTemp[key]['total'] = dadosFaltaDeArTemp[key]['total'] * multiplicador;
         dadosFebreTemp[key]['total'] = dadosFebreTemp[key]['total'] * multiplicador;
         dadosGripeTemp[key]['total'] = dadosGripeTemp[key]['total'] * multiplicador;
         dadosResfriadoTemp[key]['total'] = dadosResfriadoTemp[key]['total'] * multiplicador;
         dadosTosseTemp[key]['total'] = dadosTosseTemp[key]['total'] * multiplicador;
         dadosVomitoTemp[key]['total'] = dadosVomitoTemp[key]['total'] * multiplicador;
       };
 
       /*
       dadosGripalTemp = Object.fromEntries(
         dadosGripalTemp.map(({data, total}) => [data, total])
       ); */

       dadosCorizaTemp = Object.fromEntries(
         dadosCorizaTemp.map(({data, total}) => [data, total])
       );

       dadosCovidTemp = Object.fromEntries(
         dadosCovidTemp.map(({data, total}) => [data, total])
       );

       dadosDiarreiaTemp = Object.fromEntries(
         dadosDiarreiaTemp.map(({data, total}) => [data, total])
       );

       dadosDorDeCabecaTemp = Object.fromEntries(
         dadosDorDeCabecaTemp.map(({data, total}) => [data, total])
       );

       dadosDorDeGargantaTemp = Object.fromEntries(
         dadosDorDeGargantaTemp.map(({data, total}) => [data, total])
       );

       dadosFaltaDeArTemp = Object.fromEntries(
         dadosFaltaDeArTemp.map(({data, total}) => [data, total])
       );

       dadosFebreTemp = Object.fromEntries(
         dadosFebreTemp.map(({data, total}) => [data, total])
       );

       dadosGripeTemp = Object.fromEntries(
         dadosGripeTemp.map(({data, total}) => [data, total])
       );

       dadosResfriadoTemp = Object.fromEntries(
         dadosResfriadoTemp.map(({data, total}) => [data, total])
       );

       dadosTosseTemp = Object.fromEntries(
         dadosTosseTemp.map(({data, total}) => [data, total])
       );

       dadosVomitoTemp = Object.fromEntries(
         dadosVomitoTemp.map(({data, total}) => [data, total])
       );

       dadosCorizaTempMediaMovel = Object.fromEntries(
         dadosCorizaTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosCovidTempMediaMovel = Object.fromEntries(
         dadosCovidTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosDiarreiaTempMediaMovel = Object.fromEntries(
         dadosDiarreiaTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosDorDeCabecaTempMediaMovel = Object.fromEntries(
         dadosDorDeCabecaTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosDorDeGargantaTempMediaMovel = Object.fromEntries(
         dadosDorDeGargantaTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosFaltaDeArTempMediaMovel = Object.fromEntries(
         dadosFaltaDeArTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosFebreTempMediaMovel = Object.fromEntries(
         dadosFebreTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosGripeTempMediaMovel = Object.fromEntries(
         dadosGripeTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosResfriadoTempMediaMovel = Object.fromEntries(
         dadosResfriadoTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosTosseTempMediaMovel = Object.fromEntries(
         dadosTosseTempMediaMovel.map(({data, total}) => [data, total])
       );

       dadosVomitoTempMediaMovel = Object.fromEntries(
         dadosVomitoTempMediaMovel.map(({data, total}) => [data, total])
       );

       setDadosAtendimentoTodos(getDadosGraficoTodos(t("dashboard.graficos.titulo_atendimento_gripais"), dadosGripal.current, t("dashboard.variaveisGoogle.coriza"), dadosCorizaTemp, t("dashboard.variaveisGoogle.covid"), dadosCovidTemp, t("dashboard.variaveisGoogle.diarreia"), dadosDiarreiaTemp, t("dashboard.variaveisGoogle.dor_de_cabeca"), dadosDorDeCabecaTemp, t("dashboard.variaveisGoogle.dor_de_garganta"), dadosDorDeGargantaTemp, t("dashboard.variaveisGoogle.falta_de_ar"), dadosFaltaDeArTemp, t("dashboard.variaveisGoogle.febre"), dadosFebreTemp, t("dashboard.variaveisGoogle.gripe"), dadosGripeTemp, t("dashboard.variaveisGoogle.resfriado"), dadosResfriadoTemp, t("dashboard.variaveisGoogle.tosse"), dadosTosseTemp, t("dashboard.variaveisGoogle.vomito"), dadosVomitoTemp, false));
           
       setDadosAtendimentoTodosMediaMovel(getDadosGraficoTodos(t("dashboard.graficos.titulo_atendimento_gripais"), dadosGripalMediaMovel.current, t("dashboard.variaveisGoogle.coriza"), dadosCorizaTempMediaMovel, t("dashboard.variaveisGoogle.covid"), dadosCovidTempMediaMovel, t("dashboard.variaveisGoogle.diarreia"), dadosDiarreiaTempMediaMovel, t("dashboard.variaveisGoogle.dor_de_cabeca"), dadosDorDeCabecaTempMediaMovel, t("dashboard.variaveisGoogle.dor_de_garganta"), dadosDorDeGargantaTempMediaMovel, t("dashboard.variaveisGoogle.falta_de_ar"), dadosFaltaDeArTempMediaMovel, t("dashboard.variaveisGoogle.febre"), dadosFebreTempMediaMovel, t("dashboard.variaveisGoogle.gripe"), dadosGripeTempMediaMovel, t("dashboard.variaveisGoogle.resfriado"), dadosResfriadoTempMediaMovel, t("dashboard.variaveisGoogle.tosse"), dadosTosseTempMediaMovel, t("dashboard.variaveisGoogle.vomito"), dadosVomitoTempMediaMovel, false));
    }

    /*
  function atualizaGrafico(covid, gripe, febre, tosse, diarreia, dor_de_cabeca, resfriado, falta_de_ar, dor_de_garganta, coriza,
     vomito){
    
          let dadosCorizaTemp = [];
          let dadosCovidTemp = [];
          let dadosDiarreiaTemp = [];
          let dadosDorDeCabecaTemp = [];
          let dadosDorDeGargantaTemp = [];
          let dadosFaltaDeArTemp = [];
          let dadosFebreTemp = [];
          let dadosGripeTemp = [];
          let dadosResfriadoTemp = [];
          let dadosTosseTemp = [];
          let dadosVomitoTemp = [];

          let dadosCorizaTemp2023 = [];
          let dadosCovidTemp2023 = [];
          let dadosDiarreiaTemp2023 = [];
          let dadosDorDeCabecaTemp2023 = [];
          let dadosDorDeGargantaTemp2023 = [];
          let dadosFaltaDeArTemp2023 = [];
          let dadosFebreTemp2023 = [];
          let dadosGripeTemp2023 = [];
          let dadosResfriadoTemp2023 = [];
          let dadosTosseTemp2023 = [];
          let dadosVomitoTemp2023 = [];

          let maiorValor = 0;


          Object.keys(dadosCoriza.current).forEach((key) => {
            if(key >= datas[minIdxDate] && key <= datas[maxIdxDate]){
            

              let resultado = calculo_sintomas(!covid, !gripe, 
                !febre, !tosse, !diarreia, 
                !dor_de_cabeca, !resfriado, !falta_de_ar, 
                !dor_de_garganta, !coriza, !vomito, 
                dadosGripe.current[key], dadosFebre.current[key], dadosTosse.current[key], dadosDiarreia.current[key], 
                dadosDorDeCabeca.current[key], dadosResfriado.current[key], dadosFaltaDeAr.current[key], 
                dadosDorDeGarganta.current[key], dadosCoriza.current[key], dadosVomito.current[key]);

              //console.log(key);

              const dataReferencia = moment('2023-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss');
              const dataConvertida = moment(key, 'YYYY-MM-DD HH:mm:ss');

              //console.log(dataConvertida, dataReferencia);

              let is2023 = false;
              if(dataConvertida.isAfter(dataReferencia)){
                is2023 = true;
              }

              var obj2 = {};
              obj2['data'] = key;
              obj2['total'] = resultado['y10_'] ? resultado['y10_'] : null;
              dadosCorizaTemp.push(obj2);

              if(is2023){
                dadosCorizaTemp2023.push(obj2);
              }

              var obj3 = {};
              obj3['data'] = key;
              obj3['total'] = dadosCovid.current[key];
              dadosCovidTemp.push(obj3);

              if(is2023){
                dadosCovidTemp2023.push(obj3);
              }

              var obj4 = {};
              obj4['data'] = key;
              obj4['total'] = resultado['y5_'] ? resultado['y5_'] : null; 
              dadosDiarreiaTemp.push(obj4);
              if(is2023){
                dadosDiarreiaTemp2023.push(obj4);
              }

              var obj5 = {};
              obj5['data'] = key;
              obj5['total'] = resultado['y6_'] ? resultado['y6_'] : null; 
              dadosDorDeCabecaTemp.push(obj5);
              if(is2023){
                dadosDorDeCabecaTemp2023.push(obj5);
              }

              var obj6 = {};
              obj6['data'] = key;
              obj6['total'] = resultado['y9_'] ? resultado['y9_'] : null; 
              dadosDorDeGargantaTemp.push(obj6);
              if(is2023){
                dadosDorDeGargantaTemp2023.push(obj6);
              }

              var obj7 = {};
              obj7['data'] = key;
              obj7['total'] = resultado['y8_'] ? resultado['y8_'] : null; 
              dadosFaltaDeArTemp.push(obj7);
              if(is2023){
                dadosFaltaDeArTemp2023.push(obj7);
              }

              var obj8 = {};
              obj8['data'] = key;
              obj8['total'] = resultado['y3_'] ? resultado['y3_'] : null; 
              dadosFebreTemp.push(obj8);
              if(is2023){
                dadosFebreTemp2023.push(obj8);
              }

              var obj9 = {};
              obj9['data'] = key;
              obj9['total'] = resultado['y2_'] ? resultado['y2_'] : null; 
              dadosGripeTemp.push(obj9);
              if(is2023){
                dadosGripeTemp2023.push(obj9);
              }

              var obj10 = {};
              obj10['data'] = key;
              obj10['total'] = resultado['y7_'] ? resultado['y7_'] : null; 
              dadosResfriadoTemp.push(obj10);
              if(is2023){
                dadosResfriadoTemp2023.push(obj10);
              }

              var obj11 = {};
              obj11['data'] = key;
              obj11['total'] = resultado['y4_'] ? resultado['y4_'] : null;
              dadosTosseTemp.push(obj11);
              if(is2023){
                dadosTosseTemp2023.push(obj11);
              }

              var obj12 = {};
              obj12['data'] = key;
              obj12['total'] = resultado['y11_'] ? resultado['y11_'] : null; 
              dadosVomitoTemp.push(obj12);
              if(is2023){
                dadosVomitoTemp2023.push(obj12);
              }
            }

          });

          console.log(!covid, "preparando multiplicador");

          for (let key = 0; key < dadosCorizaTemp.length; key++) {
            if(!coriza && dadosCorizaTemp[key]["total"] > maiorValor)
              maiorValor = dadosCorizaTemp[key]["total"];
            if(!covid && dadosCovidTemp[key]["total"] > maiorValor)
              maiorValor = dadosCovidTemp[key]["total"];
            if(!diarreia && dadosDiarreiaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDiarreiaTemp[key]["total"];
            if(!dor_de_cabeca && dadosDorDeCabecaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDorDeCabecaTemp[key]["total"];
            if(!dor_de_garganta && dadosDorDeGargantaTemp[key]["total"] > maiorValor)
              maiorValor = dadosDorDeGargantaTemp[key]["total"];
            if(!falta_de_ar && dadosFaltaDeArTemp[key]["total"] > maiorValor)
              maiorValor = dadosFaltaDeArTemp[key]["total"];
            if(!febre && dadosFebreTemp[key]["total"] > maiorValor)
              maiorValor = dadosFebreTemp[key]["total"];
            if(!gripe && dadosGripeTemp[key]["total"] > maiorValor)
              maiorValor = dadosGripeTemp[key]["total"];
            if(!resfriado && dadosResfriadoTemp[key]["total"] > maiorValor)
              maiorValor = dadosResfriadoTemp[key]["total"];
            if(!tosse && dadosTosseTemp[key]["total"] > maiorValor)
              maiorValor = dadosTosseTemp[key]["total"];
            if(!vomito && dadosVomitoTemp[key]["total"] > maiorValor)
              maiorValor = dadosVomitoTemp[key]["total"];
                 
          };
          
          let multiplicador = maiorValor == 0 ? 1 : 100 / maiorValor;

          //aplique o multiplicador para todos os valores
          for (let key = 0; key < dadosCorizaTemp.length; key++) {
            dadosCorizaTemp[key]['total'] = dadosCorizaTemp[key]['total'] * multiplicador;
            dadosCovidTemp[key]['total'] = dadosCovidTemp[key]['total'] * multiplicador;
            dadosDiarreiaTemp[key]['total'] = dadosDiarreiaTemp[key]['total'] * multiplicador;
            dadosDorDeCabecaTemp[key]['total'] = dadosDorDeCabecaTemp[key]['total'] * multiplicador;
            dadosDorDeGargantaTemp[key]['total'] = dadosDorDeGargantaTemp[key]['total'] * multiplicador;
            dadosFaltaDeArTemp[key]['total'] = dadosFaltaDeArTemp[key]['total'] * multiplicador;
            dadosFebreTemp[key]['total'] = dadosFebreTemp[key]['total'] * multiplicador;
            dadosGripeTemp[key]['total'] = dadosGripeTemp[key]['total'] * multiplicador;
            dadosResfriadoTemp[key]['total'] = dadosResfriadoTemp[key]['total'] * multiplicador;
            dadosTosseTemp[key]['total'] = dadosTosseTemp[key]['total'] * multiplicador;
            dadosVomitoTemp[key]['total'] = dadosVomitoTemp[key]['total'] * multiplicador;
          };

          

          dadosCorizaTemp = Object.fromEntries(
            dadosCorizaTemp.map(({data, total}) => [data, total])
          );

          dadosCovidTemp = Object.fromEntries(
            dadosCovidTemp.map(({data, total}) => [data, total])
          );

          dadosDiarreiaTemp = Object.fromEntries(
            dadosDiarreiaTemp.map(({data, total}) => [data, total])
          );

          dadosDorDeCabecaTemp = Object.fromEntries(
            dadosDorDeCabecaTemp.map(({data, total}) => [data, total])
          );

          dadosDorDeGargantaTemp = Object.fromEntries(
            dadosDorDeGargantaTemp.map(({data, total}) => [data, total])
          );

          dadosFaltaDeArTemp = Object.fromEntries(
            dadosFaltaDeArTemp.map(({data, total}) => [data, total])
          );

          dadosFebreTemp = Object.fromEntries(
            dadosFebreTemp.map(({data, total}) => [data, total])
          );

          dadosGripeTemp = Object.fromEntries(
            dadosGripeTemp.map(({data, total}) => [data, total])
          );

          dadosResfriadoTemp = Object.fromEntries(
            dadosResfriadoTemp.map(({data, total}) => [data, total])
          );

          dadosTosseTemp = Object.fromEntries(
            dadosTosseTemp.map(({data, total}) => [data, total])
          );

          dadosVomitoTemp = Object.fromEntries(
            dadosVomitoTemp.map(({data, total}) => [data, total])
          );



          dadosCorizaTemp2023 = Object.fromEntries(
            dadosCorizaTemp2023.map(({data, total}) => [data, total])
          );

          dadosCovidTemp2023 = Object.fromEntries(
            dadosCovidTemp2023.map(({data, total}) => [data, total])
          );

          dadosDiarreiaTemp2023 = Object.fromEntries(
            dadosDiarreiaTemp2023.map(({data, total}) => [data, total])
          );

          dadosDorDeCabecaTemp2023 = Object.fromEntries(
            dadosDorDeCabecaTemp2023.map(({data, total}) => [data, total])
          );

          dadosDorDeGargantaTemp2023 = Object.fromEntries(
            dadosDorDeGargantaTemp2023.map(({data, total}) => [data, total])
          );

          dadosFaltaDeArTemp2023 = Object.fromEntries(
            dadosFaltaDeArTemp2023.map(({data, total}) => [data, total])
          );

          dadosFebreTemp2023 = Object.fromEntries(
            dadosFebreTemp2023.map(({data, total}) => [data, total])
          );

          dadosGripeTemp2023 = Object.fromEntries(
            dadosGripeTemp2023.map(({data, total}) => [data, total])
          );

          dadosResfriadoTemp2023 = Object.fromEntries(
            dadosResfriadoTemp2023.map(({data, total}) => [data, total])
          );

          dadosTosseTemp2023 = Object.fromEntries(
            dadosTosseTemp2023.map(({data, total}) => [data, total])
          );

          dadosVomitoTemp2023 = Object.fromEntries(
            dadosVomitoTemp2023.map(({data, total}) => [data, total])
          );


          setDadosAtendimentoTodos(getDadosGraficoTodos(t("dashboard.graficos.titulo_atendimento_gripais"), dadosGripal.current, t("dashboard.variaveisGoogle.coriza"), dadosCorizaTemp, t("dashboard.variaveisGoogle.covid"), dadosCovidTemp, t("dashboard.variaveisGoogle.diarreia"), dadosDiarreiaTemp, t("dashboard.variaveisGoogle.dor_de_cabeca"), dadosDorDeCabecaTemp, t("dashboard.variaveisGoogle.dor_de_garganta"), dadosDorDeGargantaTemp, t("dashboard.variaveisGoogle.falta_de_ar"), dadosFaltaDeArTemp, t("dashboard.variaveisGoogle.febre"), dadosFebreTemp, t("dashboard.variaveisGoogle.gripe"), dadosGripeTemp, t("dashboard.variaveisGoogle.resfriado"), dadosResfriadoTemp, t("dashboard.variaveisGoogle.tosse"), dadosTosseTemp, t("dashboard.variaveisGoogle.vomito"), dadosVomitoTemp, false));
              
          setDadosAtendimentoTodos2023(getDadosGraficoTodos(t("dashboard.graficos.titulo_atendimento_gripais"), dadosGripal2023.current, t("dashboard.variaveisGoogle.coriza"), dadosCorizaTemp2023, t("dashboard.variaveisGoogle.covid"), dadosCovidTemp2023, t("dashboard.variaveisGoogle.diarreia"), dadosDiarreiaTemp2023, t("dashboard.variaveisGoogle.dor_de_cabeca"), dadosDorDeCabecaTemp2023, t("dashboard.variaveisGoogle.dor_de_garganta"), dadosDorDeGargantaTemp2023, t("dashboard.variaveisGoogle.falta_de_ar"), dadosFaltaDeArTemp2023, t("dashboard.variaveisGoogle.febre"), dadosFebreTemp2023, t("dashboard.variaveisGoogle.gripe"), dadosGripeTemp2023, t("dashboard.variaveisGoogle.resfriado"), dadosResfriadoTemp2023, t("dashboard.variaveisGoogle.tosse"), dadosTosseTemp2023, t("dashboard.variaveisGoogle.vomito"), dadosVomitoTemp2023, false));
        }
          */

        const options = {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              onClick: (e, legendItem, legend) => {
                const index = legendItem.datasetIndex;
                const ci = legend.chart;
                const meta = ci.getDatasetMeta(index);
                
                // Alterna a visibilidade da variável
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                /*calculo_sintomas(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
                  ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
                  ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
                  ci.data.datasets[9].hidden, ci.data.datasets[10].hidden parei aqui)*/
                  // Atualiza o gráfico
                ci.update();
      
                let ocultaCovidTemp = ocultaCovid;
                let ocultaGripeTemp = ocultaGripe;
                let ocultaFebreTemp = ocultaFebre;
                let ocultaTosseTemp = ocultaTosse;
                let ocultaDiarreiaTemp = ocultaDiarreia;
                let ocultaDorDeCabecaTemp = ocultaDorDeCabeca;
                let ocultaResfriadoTemp = ocultaResfriado;
                let ocultaFaltaDeArTemp = ocultaFaltaDeAr;
                let ocultaDorDeGargantaTemp = ocultaDorDeGarganta;
                let ocultaCorizaTemp = ocultaCoriza;
                let ocultaVomitoTemp = ocultaVomito;
      
                switch (index) {
                  case 2:{
                    setOcultaCovid(meta.hidden ? true : false);
                    ocultaCovidTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 8:{
                    setOcultaGripe(meta.hidden ? true : false);
                    ocultaGripeTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 7:{
                    setOcultaFebre(meta.hidden ? true : false);
                    ocultaFebreTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 10:{
                    setOcultaTosse(meta.hidden ? true : false);
                    ocultaTosseTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 3:{
                    setOcultaDiarreia(meta.hidden ? true : false);
                    ocultaDiarreiaTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 4:{
                    setOcultaDorDeCabeca(meta.hidden ? true : false);
                    ocultaDorDeCabecaTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 9:{
                    setOcultaResfriado(meta.hidden ? true : false);
                    ocultaResfriadoTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 6:{
                    setOcultaFaltaDeAr(meta.hidden ? true : false);
                    ocultaFaltaDeArTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 5:{
                    setOcultaDorDeGarganta(meta.hidden ? true : false);
                    ocultaDorDeGargantaTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 1:{
                    setOcultaCoriza(meta.hidden ? true : false);
                    ocultaCorizaTemp = meta.hidden ? true : false;
                    break;
                  }
                  case 11:{
                    setOcultaVomito(meta.hidden ? true : false);
                    ocultaVomitoTemp = meta.hidden ? true : false;
                    break;
                  }
                  default:
                    break;
                }
      
                /*  
                let exibe0 = !getOcultar(prioridade.current[0]["nome"]) ? prioridade.current[0]["nome"] : "";
                let exibe1 = !getOcultar(prioridade.current[1]["nome"]) ? prioridade.current[1]["nome"] : "";
                let exibe2 = !getOcultar(prioridade.current[2]["nome"]) ? prioridade.current[2]["nome"] : "";
                let exibe3 = !getOcultar(prioridade.current[3]["nome"]) ? prioridade.current[3]["nome"] : "";
                let exibe4 = !getOcultar(prioridade.current[4]["nome"]) ? prioridade.current[4]["nome"] : "";
                let exibe5 = !getOcultar(prioridade.current[5]["nome"]) ? prioridade.current[5]["nome"] : "";
                let exibe6 = !getOcultar(prioridade.current[6]["nome"]) ? prioridade.current[6]["nome"] : "";
                let exibe7 = !getOcultar(prioridade.current[7]["nome"]) ? prioridade.current[7]["nome"] : "";
                let exibe8 = !getOcultar(prioridade.current[8]["nome"]) ? prioridade.current[8]["nome"] : "";
                let exibe9 = !getOcultar(prioridade.current[9]["nome"]) ? prioridade.current[9]["nome"] : "";
                let exibe10 = !getOcultar(prioridade.current[10]["nome"]) ? prioridade.current[10]["nome"] : "";
                */
                let exibe0 = !getOcultarTemp(prioridade.current[0]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[0]["nome"] : "";
                let exibe1 = !getOcultarTemp(prioridade.current[1]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[1]["nome"] : "";
                let exibe2 = !getOcultarTemp(prioridade.current[2]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[2]["nome"] : "";
                let exibe3 = !getOcultarTemp(prioridade.current[3]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[3]["nome"] : "";
                let exibe4 = !getOcultarTemp(prioridade.current[4]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[4]["nome"] : "";
                let exibe5 = !getOcultarTemp(prioridade.current[5]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[5]["nome"] : "";
                let exibe6 = !getOcultarTemp(prioridade.current[6]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[6]["nome"] : "";
                let exibe7 = !getOcultarTemp(prioridade.current[7]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp)? prioridade.current[7]["nome"] : "";
                let exibe8 = !getOcultarTemp(prioridade.current[8]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[8]["nome"] : "";
                let exibe9 = !getOcultarTemp(prioridade.current[9]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[9]["nome"] : "";
                let exibe10 = !getOcultarTemp(prioridade.current[10]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[10]["nome"] : "";
      
                atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10]);
      
                /*atualizaGraficoRelativo(ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp,
                  ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp);*/
      
                /*atualizaGrafico(ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp,
                  ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp);*/
                /*setOcultaCovid(ci.data.datasets[0].hidden);
                setOcultaGripe(ci.data.datasets[1].hidden);
                setOcultaFebre(ci.data.datasets[2].hidden);
                setOcultaTosse(ci.data.datasets[3].hidden);
                setOcultaDiarreia(ci.data.datasets[4].hidden);
                setOcultaDorDeCabeca(ci.data.datasets[5].hidden);
                setOcultaResfriado(ci.data.datasets[6].hidden);
                setOcultaFaltaDeAr(ci.data.datasets[7].hidden);
                setOcultaDorDeGarganta(ci.data.datasets[8].hidden);
                setOcultaCoriza(ci.data.datasets[9].hidden);
                setOcultaVomito(ci.data.datasets[10].hidden);
      
                console.log("hidden", ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
                  ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
                  ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
                  ci.data.datasets[9].hidden, ci.data.datasets[10].hidden);
      
                atualizaGrafico(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
                  ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
                  ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, ci.data.datasets[8].hidden,
                  ci.data.datasets[9].hidden, ci.data.datasets[10].hidden);*/
                
                /*for (let i = 0; i < dadosCoriza.current.length; i++) {
                  console.log(dadosCoriza.current[i]);
                  let retorno = calculo_sintomas(ci.data.datasets[0].hidden, ci.data.datasets[1].hidden,
                    ci.data.datasets[2].hidden, ci.data.datasets[3].hidden, ci.data.datasets[4].hidden,
                    ci.data.datasets[5].hidden, ci.data.datasets[6].hidden, ci.data.datasets[7].hidden, 
                    ci.data.datasets[8].hidden, ci.data.datasets[9].hidden, ci.data.datasets[10].hidden, 
                    dadosGripal.current[i], dadosCoriza.current[i], dadosCovid.current[i], dadosDiarreia.current[i], 
                    dadosDorDeCabeca.current[i], dadosDorDeGarganta.current[i], dadosFaltaDeAr.current[i], 
                    dadosFebre.current[i], dadosGripe.current[i], dadosResfriado.current[i], dadosTosse.current[i], 
                    dadosVomito.current[i]);
                }*/
                 
                //console.log(index)
              },
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: { 
                  size: 10
                }
              }
            },
            title: {
              display: false,
              color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
              text: 'Chart.js Line Chart',
            },
            /*annotation: {
              annotations: [
                {
                  type: 'line',
                  mode: 'vertical',
                  scaleID: 'x',
                  value: "2024-03-02",
                  borderColor: corLinhaMarcaoGrafico,
                  borderWidth: 1,
                },
              ],
            },*/
          },
          scales: {
            y:{
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              labelString: 'Dataset 1',
              afterBuildTicks: function(scale) {
                scale.ticks.forEach(function(tick, index, ticks) {
                  ticks[index].color = 'rgba(75,192,192,1)'; // Cor da escala do primeiro eixo y
                });
              },
              title: {
                display: true,
                text: t("dashboard.graficos.titulo_atendimento_gripais"), // Texto do título do eixo Y
                color: theme === "dark" ? "#FFFFFF" : "#000000",
                font: {
                    size: 12,
                    weight: 'bold'
                }
              }
            },
            y2:{
              type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                labelString: 'Dataset 2',
                afterBuildTicks: function(scale) {
                  scale.ticks.forEach(function(tick, index, ticks) {
                    ticks[index].color = 'rgba(255, 99, 132, 1)'; // Cor da escala do segundo eixo y
                  });
                },
                title: {
                  display: true,
                  text: t("dashboard.variaveisGoogle.termos"), // Texto do título do eixo Y
                  color: theme === "dark" ? "#FFFFFF" : "#000000",
                  font: {
                      size: 12,
                      weight: 'bold'
                  }
                }
            },
            /*x:{
              type: 'time',
              time: {
                parser: 'YYYY/MM/DD',
                unit: 'day',
                displayFormats: {
                  'day': 'DD/MM/YYYY',
                  'month' : 'DD/MM/YYYY'
                },
              },
              ticks:{
                callback: function(value, index, values) {
                  // Converte a data em um objeto moment
                 
                  return exibeMesAnoGrafico(index, values);
              },
              }
            }*/
              x: {  
                type: 'time',
                time: {
                  parser: 'YYYY/MM/DD',
                  unit: 'day',
                  displayFormats: {
                    'day': 'DD/MM/YYYY',
                    'month' : 'DD/MM/YYYY'
                  },
                },
                ticks:{
                  color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                  font: {
                    size: 10, // 'size' now within object 'font {}'
                  },
                  //stepSize: 7,
                  callback: function(value, index, values) {
                      console.log("value", value, index, semanas[index], values);
                      return exibeMesAnoGrafico(index, values);
                      
                  },
                },
                min: datas != null ? datas[minIdxDate] : null,
                max: datas != null ? datas[maxIdxDate] : null,
              }
          },
        };
  
  const options2 = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: (e, legendItem, legend) => {
          //console.log(legendItem.datasetIndex);

        },
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 15,
          color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
          font: { 
            size: 10
          }
        }
      },
      title: {
        display: false,
        color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
        text: 'Chart.js Line Chart',
      },
      /*annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x',
            value: "2024-03-02",
            borderColor: corLinhaMarcaoGrafico,
            borderWidth: 1,
          },
        ],
      },*/
    },
    scales: {
      y:{
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        labelString: 'Dataset 1',
        afterBuildTicks: function(scale) {
          scale.ticks.forEach(function(tick, index, ticks) {
            ticks[index].color = 'rgba(75,192,192,1)'; // Cor da escala do primeiro eixo y
          });
        },
        title: {
          display: true,
          text: t("dashboard.graficos.titulo_atendimento_gripais"), // Texto do título do eixo Y
          color: theme === "dark" ? "#FFFFFF" : "#000000",
          font: {
              size: 12,
              weight: 'bold'
          }
        }
      },
      y2:{
        type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          labelString: 'Dataset 2',
          afterBuildTicks: function(scale) {
            scale.ticks.forEach(function(tick, index, ticks) {
              ticks[index].color = 'rgba(255, 99, 132, 1)'; // Cor da escala do segundo eixo y
            });
          },
          title: {
            display: true,
            text: t("dashboard.variaveisGoogle.termos"), // Texto do título do eixo Y
            color: theme === "dark" ? "#FFFFFF" : "#000000",
            font: {
                size: 12,
                weight: 'bold'
            }
          }
      },
      /*x:{
        type: 'time',
        time: {
          parser: 'YYYY/MM/DD',
          unit: 'day',
          displayFormats: {
            'day': 'DD/MM/YYYY',
            'month' : 'DD/MM/YYYY'
          },
        },
        ticks:{
          callback: function(value, index, values) {
            // Converte a data em um objeto moment
           
            return exibeMesAnoGrafico(index, values);
        },
        }
      }*/
        x: {  
          type: 'time',
          time: {
            parser: 'YYYY/MM/DD',
            unit: 'day',
            displayFormats: {
              'day': 'DD/MM/YYYY',
              'month' : 'DD/MM/YYYY'
            },
          },
          ticks:{
            color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
            font: {
              size: 10, // 'size' now within object 'font {}'
            },
            //stepSize: 7,
            callback: function(value, index, values) {
                
                return exibeMesAnoGrafico(index, values);
                
            },
          },
          min: datas != null ? datas[minIdxDate] : null,
          max: datas != null ? datas[maxIdxDate] : null,
        }
    },
  };
  

  function extraiAno(data){
    if(data && data.length > 0)
        return data.substring(0,4);
    else 
        return "";
  }

  let mdbootstrap = 12;

  /*
  function mudarGrafico(index){
    //oculta dataset correspondente no gráfico
    
      const ci = chartRef.current.legend.chart;
      const meta = ci.getDatasetMeta(index);
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
      ci.update();

      let ocultaCovidTemp = ocultaCovid;
      let ocultaGripeTemp = ocultaGripe;
      let ocultaFebreTemp = ocultaFebre;
      let ocultaTosseTemp = ocultaTosse;
      let ocultaDiarreiaTemp = ocultaDiarreia;
      let ocultaDorDeCabecaTemp = ocultaDorDeCabeca;
      let ocultaResfriadoTemp = ocultaResfriado;
      let ocultaFaltaDeArTemp = ocultaFaltaDeAr;
      let ocultaDorDeGargantaTemp = ocultaDorDeGarganta;
      let ocultaCorizaTemp = ocultaCoriza;
      let ocultaVomitoTemp = ocultaVomito;


      switch (index) {
        case 2:{
          setOcultaCovid(meta.hidden ? true : false);
          ocultaCovidTemp = meta.hidden ? true : false;
          break;
        }
        case 8:{
          setOcultaGripe(meta.hidden ? true : false);
          ocultaGripeTemp = meta.hidden ? true : false;
          break;
        }
        case 7:{
          setOcultaFebre(meta.hidden ? true : false);
          ocultaFebreTemp = meta.hidden ? true : false;
          break;
        }
        case 10:{
          setOcultaTosse(meta.hidden ? true : false);
          ocultaTosseTemp = meta.hidden ? true : false;
          break;
        }
        case 3:{
          setOcultaDiarreia(meta.hidden ? true : false);
          ocultaDiarreiaTemp = meta.hidden ? true : false;
          break;
        }
        case 4:{
          setOcultaDorDeCabeca(meta.hidden ? true : false);
          ocultaDorDeCabecaTemp = meta.hidden ? true : false;
          break;
        }
        case 9:{
          setOcultaResfriado(meta.hidden ? true : false);
          ocultaResfriadoTemp = meta.hidden ? true : false;
          break;
        }
        case 6:{
          setOcultaFaltaDeAr(meta.hidden ? true : false);
          ocultaFaltaDeArTemp = meta.hidden ? true : false;
          break;
        }
        case 5:{
          setOcultaDorDeGarganta(meta.hidden ? true : false);
          ocultaDorDeGargantaTemp = meta.hidden ? true : false;
          break;
        }
        case 1:{
          setOcultaCoriza(meta.hidden ? true : false);
          ocultaCorizaTemp = meta.hidden ? true : false;
          break;
        }
        case 11:{
          setOcultaVomito(meta.hidden ? true : false);
          ocultaVomitoTemp = meta.hidden ? true : false;
          break;
        }
        default:
          break;
      }

      
      let exibe0 = !getOcultarTemp(prioridade.current[0]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[0]["nome"] : "";
      let exibe1 = !getOcultarTemp(prioridade.current[1]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[1]["nome"] : "";
      let exibe2 = !getOcultarTemp(prioridade.current[2]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[2]["nome"] : "";
      let exibe3 = !getOcultarTemp(prioridade.current[3]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[3]["nome"] : "";
      let exibe4 = !getOcultarTemp(prioridade.current[4]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[4]["nome"] : "";
      let exibe5 = !getOcultarTemp(prioridade.current[5]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[5]["nome"] : "";
      let exibe6 = !getOcultarTemp(prioridade.current[6]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[6]["nome"] : "";
      let exibe7 = !getOcultarTemp(prioridade.current[7]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp)? prioridade.current[7]["nome"] : "";
      let exibe8 = !getOcultarTemp(prioridade.current[8]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[8]["nome"] : "";
      let exibe9 = !getOcultarTemp(prioridade.current[9]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[9]["nome"] : "";
      let exibe10 = !getOcultarTemp(prioridade.current[10]["nome"], ocultaCovidTemp, ocultaGripeTemp, ocultaFebreTemp, ocultaTosseTemp, ocultaDiarreiaTemp, ocultaDorDeCabecaTemp, ocultaResfriadoTemp, ocultaFaltaDeArTemp, ocultaDorDeGargantaTemp, ocultaCorizaTemp, ocultaVomitoTemp) ? prioridade.current[10]["nome"] : "";

      console.log(prioridade.current[0]["nome"], prioridade.current[1]["nome"], prioridade.current[2]["nome"], prioridade.current[3]["nome"], prioridade.current[4]["nome"], prioridade.current[5]["nome"], prioridade.current[6]["nome"], prioridade.current[7]["nome"], prioridade.current[8]["nome"], prioridade.current[9]["nome"], prioridade.current[10]["nome"])
      console.log(exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10);

      atualizaGraficoRelativo([exibe0, exibe1, exibe2, exibe3, exibe4, exibe5, exibe6, exibe7, exibe8, exibe9, exibe10]);
    
  };*/

  function getCidades(somenteNomes) {
    var nomes_municipios = [];

    if(somenteNomes){
        nomes_municipios.unshift(
          t("dashboard.todos")
        );
        nomes_municipios.sort((a, b) => a.localeCompare(b));
    } else {
        nomes_municipios.unshift({
            "cod_ibge": 0,
            "municipio": t("dashboard.todos")
        });
        nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
    }

    return nomes_municipios;
}

  const [loading, setLoading] = useState(true);
  const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
  const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
  const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));

  const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);
  const [listaMunicipios, setListaMunicipios] = useState(getCidades());

  var defaultPropsMunicipio = {
      options: cidades_labels,
      getOptionLabel: (option) => option.label,
  };

  var estados_labels = [
    {label:t("dashboard.todos")},
    {label:"Acre"},
    {label:"Alagoas"},
    {label:"Amapá"},
    {label:"Amazonas"},
    {label:"Bahia"},
    {label:"Ceará"},
    {label:"Distrito Federal"},
    {label:"Espírito Santo"},
    {label:"Goiás"},
    {label:"Maranhão"},
    {label:"Mato Grosso"},
    {label:"Mato Grosso do Sul"},
    {label:"Minas Gerais"},
    {label:"Pará"},
    {label:"Paraíba"},
    {label:"Paraná"},
    {label:"Pernambuco"},
    {label:"Piauí"},
    {label:"Rio de Janeiro"},
    {label:"Rio Grande do Norte"},
    {label:"Rio Grande do Sul"},
    {label:"Rondônia"},
    {label:"Roraima"},
    {label:"Santa Catarina"},        
    {label:"São Paulo"},
    {label:"Sergipe"},
    {label:"Tocantins"},
];

var defaultProps = {
    options: estados_labels,
    getOptionLabel: (option) => option.label,
};

const getNomeCidadeWhereCodIbge = (cod_ibge) => {
  for (let index = 0; index < cidades_labels.length; index++) {
    const element = cidades_labels[index];
    if(element["cod_ibge"] === cod_ibge){
        return element["label"];
    }
  }
  return t("dashboard.todos");
};

const handleChangeMunicipioNome = (nome_municipio) => {
  setCod_Ibge(nome_municipio);
  setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
};

useEffect(() => {
  
  if(estadoSelecionado){
    
    let siglaEstado = "";
    let codEstado = "";
    for (let index = 0; index < cod_estados.length; index++) {
      const element = cod_estados[index];
      //compare em minusculo
      if(element["NOME"].toLowerCase() === estadoSelecionado.toLowerCase()){
          siglaEstado = element["UF"];
          codEstado = element["COD"];
          codEstado = codEstado.toString();
          break;
      }
    }
    
    carregaMunicipiosPorEstado(siglaEstado);
    if(!isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)){
      carregaDadosMunicipio(codEstado);
    }
    else
    if(isAllSelect(estadoSelecionado)){  
      setCidades_Labels([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);
  
      if(listaMunicipios.length === 1){
        setListaMunicipios(getCidades());
      }

      carregaDadosMunicipio(t("dashboard.todos"));
    }
  }
}, [estadoSelecionado, municipioSelecionado]);

useEffect(() => {
  if(cod_ibge === t("dashboard.todos") && estadoSelecionado === t("dashboard.todos")){
    carregaDadosMunicipio(t("dashboard.todos"));
    console.log("1");
  } 
  else   
  if(!isAllSelect(cod_ibge)){
    carregaDadosMunicipio(cod_ibge);
    console.log("2");
  }
}, [cod_ibge]);

function carregaMunicipiosPorEstado(siglaEstado){
  if(!loading){
      setLoading(true);
  }

  if(siglaEstado !== ''){
      fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
          , {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
      )
      .then(function (response) {
          //Limpa pontos anteriores
          return response.json();
      })
      .then(function (myJson) {
          let listaTemp = [];
          let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
          for(let i = 0; i < myJson.length; i++){
              listaTemp.push({
                  "cod_ibge": myJson[i]["COD_IBGE"],
                  "municipio": myJson[i]["MUNICIPIO"],
                  "latitude": myJson[i]["Y"],
                  "longitude": myJson[i]["X"]
              });

              cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
          }

          

          setCidades_Labels(cidades_labels_tmp);

          listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
          
          listaTemp.unshift({
              "cod_ibge": 0,
              "municipio": t("dashboard.todos")
          });

          setListaMunicipios(listaTemp); 

      });
  }
}

  //deixe o acordion aberto
  const [open, setOpen] = useState(true);

  const [listaMediaMovel, setlistaMediaMovel] = useState(
  [
    {"sinal": "1", "nome": "1 Semana"},
    {"sinal": "2", "nome": "2 Semanas"},
    {"sinal": "3", "nome": "3 Semanas"},
    {"sinal": "4", "nome": "4 Semanas"},
  ]);

  const [listaVariavelMediaMovel, setListaVariavelMediaMovel] = useState(
    [
      {"sinal": "1", "nome": t("dashboard.variaveisGoogle.coriza")},
      {"sinal": "2", "nome": t("dashboard.variaveisGoogle.covid")},
      {"sinal": "3", "nome": t("dashboard.variaveisGoogle.diarreia")},
      {"sinal": "4", "nome": t("dashboard.variaveisGoogle.dor_de_cabeca")},
      {"sinal": "5", "nome": t("dashboard.variaveisGoogle.dor_de_garganta")},
      {"sinal": "6", "nome": t("dashboard.variaveisGoogle.falta_de_ar")},
      {"sinal": "7", "nome": t("dashboard.variaveisGoogle.febre")},
      {"sinal": "8", "nome": t("dashboard.variaveisGoogle.gripe")},
      {"sinal": "9", "nome": t("dashboard.variaveisGoogle.resfriado")},
      {"sinal": "10", "nome": t("dashboard.variaveisGoogle.tosse")},
      {"sinal": "11", "nome": t("dashboard.variaveisGoogle.vomito")},
    ]);

  const handleChangeMediaMovel = (event) => {
    setMediaMovelSelecionada(event.target.value);
  };

  const handleChangeVariavelMediaMovel = (event) => {
    setVariavelMediaMovelSelecionada(event.target.value);
  };

  useEffect(() => {
    console.log(mediaMovelSelecionada);
    if(dadosCoriza.current){
      atualizaMediaMovel();
    }
  }, [mediaMovelSelecionada, variavelMediaMovelSelecionada]);

  return (
    <div className="container_google_trend">
      <Accordion   defaultActiveKey="0">
      <Accordion.Item  eventKey="0" >
        <Accordion.Header className={theme === "dark" ? "header-dark" : "header-light"}>Google Trends</Accordion.Header>
        <Accordion.Body  className={theme === "dark" ? "bg-dark text-white" : "bg-light text-dark"}>
            <Container>
                <div className={style.seleciona_periodo_semana}>
                    <div className='titulo_semana_ep'>
                      <Translator path="dashboard.semana_ep" />: {FormatarDoisDigitos(inicioSemanaSelecionadaRange)+ "-" + extraiAno(minDateRangeFinal)}&nbsp; a &nbsp;{FormatarDoisDigitos(fimSemanaSelecionadaRange)+ "-" + extraiAno(maxDateRangeFinal)}
                    </div>
                </div>
                <div className={style.seleciona_periodo}>
                    <div className='dataSemanaRange'>
                        <Moment date={minDateRangeInicial} format='DD/MM/YYYY'></Moment>&nbsp; a &nbsp;<Moment date={minDateRangeFinal} format='DD/MM/YYYY'></Moment>
                    </div>
                    <Box sx={{ width: "220px", paddingTop:"0px", marginTop:"-5px" }}>
                        <Slider
                            getAriaLabel={() => 'Date range'}
                            value={valueRangeData}
                            onChange={handleChangeRangeData}
                            valueLabelDisplay="off"
                            min={0}
                            max={datas != null ? datas.length - 1 : 0}
                        />
                    </Box>
                    <div className='dataSemanaRange'>
                        <Moment date={maxDateRangeInicial} format='DD/MM/YYYY'></Moment>&nbsp; a &nbsp;<Moment date={maxDateRangeFinal} format='DD/MM/YYYY'></Moment>
                    </div>
                </div>
                
                <div className={style.seleciona_municipio}> 
                  <Autocomplete
                      {...defaultProps}
                      disableClearable
                      id="autocomplete_estado"
                      className='change_color_text'
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      value={{label:estadoSelecionado}}
                      style={{ width: 300 }}
                      onChange={(event, newValue) => {
                          setLoading(true);
                          setEstadoSelecionado(newValue.label);
                          setCod_Ibge(t("dashboard.todos"));
                          setMunicipioSelecionado(t("dashboard.todos"));
                      }}
                      renderInput={(params) => (
                          <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                      )}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  
                  <Autocomplete
                      {...defaultPropsMunicipio}
                      disableClearable
                      id="autocomplete_municipio"
                      className='change_color_text'
                      isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                      value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                      style={{ width: 300 }}
                      onChange={(event, newValue) => {
                          handleChangeMunicipioNome(newValue.cod_ibge);
                      }}
                      renderInput={(params) => (
                          <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                      )}
                  />
              </div>
              
                
              <br></br>
              <Row>
                <Col md={mdbootstrap}>
                  <div className="grafico_g">
                    <Line ref={chartRef} data={dadosAtendimentoTodos} options={options} />
                  </div>
                </Col>
                <div style={{marginTop:30, marginBottom:10}}>
                  <FormControl id="controle-selecao-var-media-movel">
                    <InputLabel id="demo-simple-select-label">{t("dashboard.variavel")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={variavelMediaMovelSelecionada}
                      onChange={handleChangeVariavelMediaMovel}
                      label={t("dashboard.variavel")}
                      name={"variavel_media_movel"}
                      sx={{ width: 160, height: 32}}
                    >
                    {listaVariavelMediaMovel.map((e, key) => {
                      return (
                        <MenuItem key={key} value={e["sinal"]}>{e["nome"]}</MenuItem>
                      );
                    })}
                    </Select>
                  </FormControl>
                  <FormControl id="controle-selecao-indicador">
                    <InputLabel id="demo-simple-select-label">{t("dashboard.graficos.legenda_mm.1")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mediaMovelSelecionada}
                      onChange={handleChangeMediaMovel}
                      label={t("dashboard.graficos.legenda_mm.1")}
                      name={"media_movel"}
                      sx={{ width: 120, height: 32}}
                    >
                    {listaMediaMovel.map((e, key) => {
                      return (
                        <MenuItem key={key} value={e["sinal"]}>{e["nome"]}</MenuItem>
                      );
                    })}
                    </Select>
                  </FormControl> 
                </div>
                <Col md={mdbootstrap}>
                  <div className="grafico_g">
                    <Line data={dadosMediaAtendimentoTodos} options={options2} />
                  </div>
                </Col>
              </Row>
          
            </Container>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      <br></br>
    </div>
  );

}

export default WebBased;