export const lightTheme = {
    body: '#F5f7f7',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#FFF',
    a: '#000',
    a_text_hover : '#fff',
    a_hover: '#505251',
    background_image: 'imagens/bkg_white.jpg',
    background_maximize: 'imagens/icones/icon-expandir-black.png',
    /*background_image: 'imagens/bkg_black.jpg',*/
    toggler_icon: '0%',
    aesop_logo: '100%',
    icone_dengue: '0%',
    icone_gripe: '0%',
    fiocruz_logo: '100%',
    rockefeller_logo: '0%',
    coppe_logo: '0%',
    cor_diferenciada: '#3f7d61',
    texto_noticia: '#000',
    fundo_noticia: '#fff',
    fundo_navbar: '255, 255, 255, 1.00', //60
    legenda_graficos: '#fff',
    background_grafico: '#dedde0'/*'#d0cfd3'*/,
    noticia_home_bg: '#C5C5C5',
    lasted_news_bg: '#797979',
    fundo_retangulo: '#C5c5c5',
    texto_retangulo: '#000',
    cor_nome_layer: 'rgba(170, 170, 170, 0.9)',
}

export const darkTheme = {
    body: '#000',
    text: '#fff',
    toggleBorder: '#6B8096',
    background: '#999',
    a : '#ffff',
    a_text_hover : '#fff',
    a_hover: '#D2d2d2',
    background_image: 'imagens/bkg_black.jpg',
    background_maximize: 'imagens/icones/icon-expandir-white.png',
    toggler_icon: '100%',
    aesop_logo: '0%',
    fiocruz_logo: '0%',
    rockefeller_logo: '100%',
    coppe_logo: '100%',
    icone_dengue: '100%',
    icone_gripe: '100%',
    cor_diferenciada: '#75ffc0',
    texto_noticia: '#fff',
    fundo_noticia: '#000',
    fundo_navbar: '0, 0, 0, 1.00', //40
    legenda_graficos: '#fff',
    background_grafico: '#222327',
    noticia_home_bg: '#2b2b2b',
    lasted_news_bg: '#000',
    fundo_retangulo: '#5E5E5E',
    texto_retangulo: '#fff',
    cor_nome_layer: 'rgba(110, 111, 111, 0.9)',
}