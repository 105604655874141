import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import 'leaflet/dist/leaflet.css'

import './i18n'

import {Chart, ArcElement, CategoryScale, LinearScale, BarElement, Title,
  Tooltip,
  Legend, TimeScale, LogarithmicScale, TimeSeriesScale, PointElement, LineElement, 
  Filler} from 'chart.js'
import Annotation from 'chartjs-plugin-annotation';
import {SankeyController, Flow} from 'chartjs-chart-sankey';

Chart.register(
              ArcElement,
              CategoryScale,
              LinearScale,
              BarElement,
              Title,
              Tooltip,
              Legend,
              TimeScale,
              LogarithmicScale,
              TimeSeriesScale,
              PointElement,
              LineElement,
              Filler,
              Annotation,
              SankeyController, Flow
              );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  //<React.StrictMode>
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>  
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
