import React, { useContext, useEffect, useState } from 'react'

import { TemaContext } from '../../common/context/TemaContext';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import {SankeyController, Flow} from 'chartjs-chart-sankey';
import {Chart} from 'react-chartjs-2';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation} from 'react-i18next';
import Translator from '../I18n/Translator';
import style from './Sankey.module.css'
import './Sankey.css'
import { jsPDF } from "jspdf";

var posicao = localStorage.getItem('posicaoScroll');

const Sankey = () => {
    const { t } = useTranslation();
    posicao = localStorage.getItem('posicaoScroll');
    const [campoOrdemTable1, setCampoOrdemTable1] = useState("3");
    const [decrescenteTable1, setDecrescenteTable1] = useState(true);
    const [campoOrdemTable2, setCampoOrdemTable2] = useState("5");
    const [decrescenteTable2, setDecrescenteTable2] = useState(true);
    const [campoOrdemTable3, setCampoOrdemTable3] = useState("3");
    const [decrescenteTable3, setDecrescenteTable3] = useState(true);
    const [listaMunicipios, setListaMunicipios] = useState([]);
    //const {theme} = useContext(TemaContext);
    const [markers, setMarkers] = useState([]);
    const [tabela1, setTabela1] = useState({
        header: [],
        data: [],
    });
    const [tabela2, setTabela2] = useState({
        header: [],
        data: [],
    });
    const [tabela3, setTabela3] = useState({
        header: [],
        data: [],
    });

    const {theme, setTheme} = useContext(TemaContext);

    var corFonteSankey = theme === "dark" ? "white" : "black";

    var nomes_estados = (require('../Mapa/Dados/estados.json'));

    var cod_estados = [
        {"UF":"AC",
        "COD": 12},
        {"UF":"AL",
        "COD": 27},
        {"UF":"AP",
        "COD": 16},
        {"UF":"AM",
        "COD": 13},
        {"UF":"BA",
        "COD": 29},
        {"UF":"CE",
        "COD": 23},
        {"UF":"DF",
        "COD": 53},
        {"UF":"ES",
        "COD": 32},
        {"UF":"GO",
        "COD": 52},
        {"UF":"MA",
        "COD": 21},
        {"UF":"MT",
        "COD": 51},
        {"UF":"MS",
        "COD": 50},
        {"UF":"MG",
        "COD": 31},
        {"UF":"PA",
        "COD": 15},
        {"UF":"PB",
        "COD": 25},
        {"UF":"PR",
        "COD": 41},
        {"UF":"PE",
        "COD": 26},
        {"UF":"PI",
        "COD": 22},
        {"UF":"RJ",
        "COD": 33},
        {"UF":"RN",
        "COD": 24},
        {"UF":"RS",
        "COD": 43},
        {"UF":"RO",
        "COD": 11},
        {"UF":"RR",
        "COD": 14},
        {"UF":"SC",
        "COD": 42},        
        {"UF":"SP",
        "COD": 35},
        {"UF":"SE",
        "COD": 28},
        {"UF":"TO",
        "COD": 17},
    ];
    
    const [dataSankey, setDataSankey] = useState({
        datasets: [
          {
            label: "My sankey",
            data: [],
            colorMode: "gradient",
            size: "max",
            labels: [],
          }
        ],
        
    });

    const [carregou, setCarregou] = useState(false);
    
    const optionsSankey = {
        plugins: {
            tooltip: {
                
                labels: {
                    color: 'white'
                }
            },
            legend: {
                display: false
            }
        }, 
      };

    var colorsSankey_ = {
        'Grupo 1': '#106ae4', 'Manaus': '#b33407', 'Grupo 2': '#f69b05', 'Itacoatiara': '#ab5032', 'Grupo 3': '#fad037', 'Parintins': '#132e53', 'Santarém': '#a9329a', 'São Paulo': '#323ba9', 'Belém': '#32a99d', 'Campinas': '#277ee3', 'Porto Velho': '#d1510c', 'Fortaleza': '#ff8411', 'Cascavel': '#8a5e51', 'Estrela': '#ffde48', 'Porto Alegre': '#263e65', 'Campina Grande': '#d34dbb', 'Caruaru': '#343fd4', 'Blumenau': '#34d4c5', 'Belo Horizonte': '#38af4b', 'Rio de Janeiro': '#d43563', 'Teresina': '#7e4633', 'Feira de Santana': '#caa844', 'Vitória': '#24482a', 'Bauru': '#e74f83', 'Carazinho': '#9632d7', 'Grupo 4': '#237b92', 'Non Hubs': '#b23e41', 'Ariquemes': '#d68909', 'Grupo 5': '#b3551b', 'Others Hubs': '#805372', 'Óbidos': '#2f4974', 'Others Non Hubs': '#c16298', 'Others': '#2039db', 'Others - Parintins': '#38b4c2', 'Others - Manaus': '#bfd018', 'Others - Itacoatiara': '#1e483e', 'Others - Santarém': '#cf2a19', 'Others - São Paulo': '#207bb8', 'Others - Belém': '#11806a', 'Others - Campinas': '#71368a'
    };

    window.onscroll = function (e) {
        posicao = window.scrollY;
        localStorage.setItem('posicaoScroll', JSON.stringify(posicao));
    }

    const getCodEstadoBySigla = (sigla) => {
        for(let i = 0; i < cod_estados.length; i++){
            if(cod_estados[i]["UF"] === sigla){
                return cod_estados[i]["COD"];
            }
        }
    }
  
    function getColorSankey(colorsSankey, name) {
        return colorsSankey[name] || "green";
    }

    const handleKeyUpEstado = (event) => {
        if(event["keyCode"] === 13){
            /*#verificar a mudança do estado com enter*/
        }
    };

    const handleChangeEstado = (event) => {
        setEstadoSelecionado(event.target.value);
    };

    const getEstados = () => { 
        let nomes_estados_tmp = [];
        
        for (let i=0; i < nomes_estados.length; i++){
            nomes_estados_tmp.push(nomes_estados[i]);
        }       
        
        return nomes_estados_tmp;
    }

    function getCidades(somenteNomes) {
        var nomes_municipios = [];

        if(somenteNomes){
            nomes_municipios.unshift(
                t("dashboard.todos")
            );
            nomes_municipios.sort((a, b) => a.localeCompare(b));
        } else {
            nomes_municipios.unshift({
                "cod_ibge": 0,
                "municipio": t("dashboard.todos")
            });
            nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
        }

        return nomes_municipios;
    }

    const [listaEstados, setListaEstados] = useState([]);

    const [estadoSelecionado, setEstadoSelecionado] = useState("");
    const [municipioSelecionado, setMunicipioSelecionado] = useState(null);

    const parseCSV = (text) => {
        const result = {
            header: [],
            data: [],
        };

        const [header, ...content] = text.split('\n');

        result.header = header.replace('\r', '').split(',');

        content.forEach((item) => {
            if(item.split(',').length > 1){
                result.data.push(item.split(','));
            }
        });

        return result;
    };

    const carregarTable = (num_table, cod_uf) => {
        fetch('/Dados/tabelas_sankey/table'+num_table+'_'+cod_uf+'.csv')
        .then((r) => r.text())
        .then((text) => {
            if(num_table === 1)
                setTabela1(parseCSV(text));
            else if(num_table === 2)
                setTabela2(parseCSV(text));
            else 
                setTabela3(parseCSV(text));
        });
    }

    useEffect(() => {
        setListaEstados(getEstados());
        carregarTable(1,12);
        carregarTable(2,12);
        carregarTable(3,1200013);
        localStorage.setItem('posicaoScroll', JSON.stringify(0));
    }, []);

    useEffect(() => {
        if(listaEstados.length > 0){
            setEstadoSelecionado(listaEstados[0]["ESTADO"]);
            setCarregou(true);
        }
    }, [listaEstados]);

    useEffect(() => {
        async function fetchSankeyEstado() {
            if(estadoSelecionado === null){
                return;
            }
            try {
                const response = await fetch('Mobilidade/sankey/dados_' + estadoSelecionado + '.json');
                if (response.ok) {
                    const data = await response.json();
                    let chartData = (data["chartData"]);
                    let labels = (data["labels"]);
                    labels = labels.reduce((result, item) => {
                        const key = Object.keys(item)[0];
                        const value = item[key];
                        result[String(key)] = value;
                        return result;
                    });
                    let colorsSankey = (data["colors"]);
                    colorsSankey = colorsSankey.reduce((result, item) => {
                        const key = Object.keys(item)[0];
                        const value = item[key];
                        result[String(key)] = value;
                        return result;
                    })
                    setDataSankey({
                        datasets: [
                          {
                            label: "My sankey",
                            data: chartData,
                            colorMode: "gradient",
                            size: "max", //"max"
                            colorFrom: (c) => getColorSankey(colorsSankey, c.dataset.data[c.dataIndex].from),
                            colorTo: (c) => getColorSankey(colorsSankey, c.dataset.data[c.dataIndex].to),
                            labels: labels,
                          }
                        ],
                        
                    });
                    if(!carregou){
                        setCarregou(true);
                    }
                }
            } catch (error) {
                console.log('Erro ao buscar arquivo.');
                setCarregou(false);
            }
        }
    
        if (estadoSelecionado) {
            
           
            fetchSankeyEstado();
            var siglaEstado = "";
            for (let index = 0; index < listaEstados.length; index++) {
                const element = listaEstados[index];
                
                if(estadoSelecionado === element["ESTADO"]){ 
                    var codEstado = getCodEstadoBySigla(listaEstados[index]["UF"]);
                    siglaEstado = listaEstados[index]["UF"];
                    carregarTable(1,codEstado);
                    carregarTable(2,codEstado);
                    //carregarTable(3,1200013);
                    break;
                }
            }

            if(siglaEstado !== ''){
                fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
                                        , {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Accept': 'application/json'
                                            }
                                        }
                                    )
                                    .then(function (response) {
                                        //Limpa pontos anteriores
                                        return response.json();
                                    })
                                    .then(function (myJson) {
                                        let listaTemp = [];
                                        for(let i = 0; i < myJson.length; i++){
                                            listaTemp.push({
                                                "cod_ibge": myJson[i]["COD_IBGE"],
                                                "municipio": myJson[i]["MUNICIPIO"]
                                            });
                                        }
    
                                        listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));

                                        setListaMunicipios(listaTemp);  

                                        setMunicipioSelecionado(listaTemp[0]["cod_ibge"]);
                                    });
            }
        }
    }, [estadoSelecionado]);


    const tabelaOrdenada = (tabela, campoOrdem, decrescente, num_tabela) => {
     
        if(tabela.length === 0){
            return [];
        }

        var tTabela = tabela;

        var campoOrdemLocal = campoOrdem;
        var intCampoOrdemLocal = parseInt(campoOrdemLocal);
        if(num_tabela === 1 || num_tabela === 2){
            if (campoOrdemLocal === "2" || campoOrdemLocal === "3") {
                if (decrescente) {
                    tTabela.sort(function (a, b) { return b[intCampoOrdemLocal] - a[intCampoOrdemLocal]; });
                } else {
                    tTabela.sort(function (a, b) { return a[intCampoOrdemLocal] - b[intCampoOrdemLocal]; });
                }
            }
            else
                if (campoOrdemLocal === "0" || campoOrdemLocal === "1") {
                    if (decrescente) {
                        tTabela.sort((a, b) => (b[intCampoOrdemLocal].localeCompare(a[intCampoOrdemLocal])));
                    } else {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal].localeCompare(b[intCampoOrdemLocal])));
                    }
                } else {
                    if (decrescente) {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal] > b[intCampoOrdemLocal]) ? -1 : 1);
                    } else {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal] > b[intCampoOrdemLocal]) ? 1 : -1);
                    }
                }
        } else {
            if (campoOrdemLocal === "2" || campoOrdemLocal === "3") {
                if (decrescente) {
                    tTabela.sort(function (a, b) { return b[intCampoOrdemLocal] - a[intCampoOrdemLocal]; });
                } else {
                    tTabela.sort(function (a, b) { return a[intCampoOrdemLocal] - b[intCampoOrdemLocal]; });
                }
            }
            else
                if (campoOrdemLocal === "0" || campoOrdemLocal === "1") {
                    if (decrescente) {
                        tTabela.sort((a, b) => (b[intCampoOrdemLocal].localeCompare(a[intCampoOrdemLocal])));
                    } else {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal].localeCompare(b[intCampoOrdemLocal])));
                    }
                } else {
                    if (decrescente) {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal] > b[intCampoOrdemLocal]) ? -1 : 1);
                    } else {
                        tTabela.sort((a, b) => (a[intCampoOrdemLocal] > b[intCampoOrdemLocal]) ? 1 : -1);
                    }
                }
        }

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }

        return tTabela;
    }

    const trataNaNeNull = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? "nil" : dado;
    }

    const trataNaNeNullInt = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? "nil" : parseInt(dado);
    }

    const preparaInt = (valor) => {
        var valor_temp = valor + "";
        valor_temp = valor_temp.replace(".", "");
        valor_temp = valor_temp.replace(",", "");
        return valor_temp;
    }

    const fixaCasasDecimais = (valor, casasDecimais) => {
        if(valor === "nil") 
            return "nil";
        return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
    }

    function handleChangeEstadoTabela (estado_selecionado){
        
    }

    const handleChangeMunicipioTabela = (cod_ibge) => {
        
    }

    const handleChangeMunicipio = (event) => {
        setMunicipioSelecionado(event.target.value);
        carregarTable(3,event.target.value);
    }

    const handleDownloadPDF = () => {
       /* const pdf = new jsPDF();
        pdf.save("sankey.pdf");*/
        const canvas = document.getElementById('sankeyplot');
        //create image
        const canvasImage = canvas.toDataURL('image/jpeg', 1.0);
        
        // image must go to PDF
        let pdf = new jsPDF('landscape');
        pdf.setFontSize(20);
        pdf.addImage(canvasImage, 'JPEG', 15, 15, 280, 150);
        pdf.save('mychart.pdf');
    }
  
    return (
        <div className={style.container}>
            <br></br>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl onKeyUp={handleKeyUpEstado} >
                <InputLabel id="demo-simple-select-label">{t("dashboard.estado")}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={estadoSelecionado}
                    onChange={handleChangeEstado}
                    label={t("dashboard.estado")}
                    name={"estados"}
                    sx={{ width: 190, height: 30}}
                >
                {listaEstados.map((e, key) => {
                    return (
                        <MenuItem key={key} value={e["ESTADO"]}>{e["ESTADO"]}</MenuItem>
                    );
                })}
                </Select>
            </FormControl> 

            {carregou ?
                <div className='div_sankey'>
                    <Chart
                        id="sankeyplot"
                        type="sankey"
                        data={dataSankey}
                        options={optionsSankey}
                    /> 
                </div>: <div className='div_sankey'>&nbsp;&nbsp;{t("sankey.sem_dados")}</div> 
                
            }
               <button className={style.button} onClick={handleDownloadPDF}>{t("sankey.download")}</button>
                <div className={style.tabelas}>
                    <br/>
                    <br/>
                    <div className={style.table}>
                        <Table id="dtBasicExample" striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                            <thead>
                                <tr>
                                    <th><a href='#' style={{ color: campoOrdemTable1 === "0" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable1 === "0") { setDecrescenteTable1(!decrescenteTable1); } else { setDecrescenteTable1(false); }
                                        setCampoOrdemTable1("0")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col1tab1")} </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable1 === "1" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdemTable1 === "1") { setDecrescenteTable1(!decrescenteTable1); } else { setDecrescenteTable1(false); }
                                            setCampoOrdemTable1("1")
                                        }}>
                                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col2tab1")} </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable1 === "2" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable1 === "2") { setDecrescenteTable1(!decrescenteTable1); } else { setDecrescenteTable1(false); }
                                        setCampoOrdemTable1("2")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col3tab1")}  </a></th>
                                    <th><a href='#' style={{ color: campoOrdemTable1 === "3" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable1 === "3") { setDecrescenteTable1(!decrescenteTable1); } else { setDecrescenteTable1(false); }
                                        setCampoOrdemTable1("3")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col4tab1")}  </a></th>

                                </tr>
                            </thead>
                            <tbody>
                                {tabelaOrdenada(tabela1['data'], campoOrdemTable1, decrescenteTable1, 1).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value[0]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value[0]}</a></td>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value[1]) }}>{value[1]}</a></td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[2])}</td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[3])}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>

                    <br/>
                    <br/>
                    <div className={style.table}>        
                        <Table id="dtBasicExample" striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                            <thead>
                                <tr>
                                    <th><a href='#' style={{ color: campoOrdemTable2 === "0" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable2 === "0") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                        setCampoOrdemTable2("0")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col1tab2")} </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable2 === "1" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdemTable2 === "1") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                            setCampoOrdemTable2("1")
                                        }}>
                                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col2tab2")}  </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable2 === "2" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable2 === "2") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                        setCampoOrdemTable2("2")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col3tab2")}  </a></th>

                                    <th><a href='#' style={{ color: campoOrdemTable2 === "3" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable2 === "3") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                        setCampoOrdemTable2("3")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col4tab2")}  </a></th>

                                    <th><a href='#' style={{ color: campoOrdemTable2 === "4" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable2 === "4") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                        setCampoOrdemTable2("4")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col5tab2")} </a></th>

                                    <th><a href='#' style={{ color: campoOrdemTable2 === "5" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable2 === "5") { setDecrescenteTable2(!decrescenteTable2); } else { setDecrescenteTable2(false); }
                                        setCampoOrdemTable2("5")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col6tab2")}  </a></th>

                                </tr>
                            </thead>
                            <tbody>
                                {tabelaOrdenada(tabela2['data'], campoOrdemTable2, decrescenteTable2, 2).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value[0]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value[0]}</a></td>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value[1]) }}>{value[1]}</a></td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[2])}</td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[3])}</td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[4])}</td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[5])}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <br/>
                    <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">{t("dashboard.cidade")}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(municipioSelecionado === null || listaMunicipios.length <= 1) ? "" : municipioSelecionado }
                            onChange={handleChangeMunicipio}
                            label={t("dashboard.cidade")}
                            name={"municipios"}
                            sx={{ width: 190, height: 30}}
                        >
                        {listaMunicipios.map((e, key) => {
                            return (
                                <MenuItem key={key} value={e["cod_ibge"]}>{e["municipio"]}</MenuItem>
                            );
                        })}
                        </Select>
                    </FormControl>
                    <br/>
                    <br/> 
                    <div className={style.table}>
                        <Table id="dtBasicExample" striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                            <thead>
                                <tr>
                                    <th><a href='#' style={{ color: campoOrdemTable3 === "0" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable3 === "0") { setDecrescenteTable3(!decrescenteTable3); } else { setDecrescenteTable3(false); }
                                        setCampoOrdemTable3("0")
                                    }}>
                                    <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col1tab3")}  </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable3 === "1" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdemTable3 === "1") { setDecrescenteTable3(!decrescenteTable3); } else { setDecrescenteTable3(false); }
                                            setCampoOrdemTable3("1")
                                        }}>
                                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> {t("sankey.col2tab3")}  </a>
                                    </th>

                                    <th><a href='#' style={{ color: campoOrdemTable3 === "2" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable3 === "2") { setDecrescenteTable3(!decrescenteTable3); } else { setDecrescenteTable3(false); }
                                        setCampoOrdemTable3("2")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col3tab3")} </a></th>

                                    <th><a href='#' style={{ color: campoOrdemTable3 === "3" ? "cadetblue" : ""}} onClick={() => {
                                        if (campoOrdemTable3 === "3") { setDecrescenteTable3(!decrescenteTable3); } else { setDecrescenteTable3(false); }
                                        setCampoOrdemTable3("3")
                                    }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> {t("sankey.col4tab3")} </a></th>

                                </tr>
                            </thead>
                            <tbody>
                                {tabelaOrdenada(tabela3['data'], campoOrdemTable3, decrescenteTable3, 3).map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value[0]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value[0]}</a></td>
                                            <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value[1]) }}>{value[1]}</a></td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[2])}</td>
                                            <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{trataNaNeNull(value[3])}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                
        </div>
    )
}

export default Sankey