import styles from './Retangulo.module.css';

const abreviaUnidades = (valor, t) => {
  var valorlocal = valor;
  if(valor >= 1000000 && valor <= 999999999) {
      valorlocal = parseFloat(parseInt(valor) / 1000000).toFixed(3);
      return valorlocal + t("dashboard.retangulos.milhoes");
  } else if(valor >= 1000000000 && valor <= 999999999999){
      valorlocal = parseFloat(parseInt(valor) / 1000000000).toFixed(3);
      return valorlocal + t("dashboard.retangulos.bilhoes");
  } else return valor;
}

const Retangulo = ({cor, nome, valor, perc, abrevia, t}) => {
  return (
    <div style={{backgroundColor:cor}} className={styles.container_retangulo}>
         <div className={styles.nome_variavel_retangulo}>{nome}</div>
         <div className={styles.valor_variavel_retangulo}>{(valor === "NaN" || isNaN(parseFloat(valor))) ? "-" : (Number.isInteger(valor) ? (abrevia ? abreviaUnidades(valor, t) : valor) : parseFloat(valor).toFixed(2))}{perc != null && perc ? "%" : ""}</div>
    </div>
  )
}

export default Retangulo