import React from 'react';
import { Table } from 'react-bootstrap';
import style from './Tabela.module.css';
import Translator from '../I18n/Translator';

const valorNil = 'nil'; // Substitua por qualquer valor padrão desejado para casos em que o valor é nulo

const isAllSelect = (variavel) => {
    return variavel === "All" || variavel === "Todos";
}

const preparaInt = (valor) => {
    var valor_temp = valor + "";
    valor_temp = valor_temp.replace(".", "");
    valor_temp = valor_temp.replace(",", "");
    return valor_temp;
}

const trataNaNeNull = (dado) => {
    return dado === null || dado === "" || dado === "NaN" ? valorNil : dado;
}

const trataNaNeNullInt = (dado) => {
    return dado === null || dado === "" || dado === "NaN" ? valorNil : parseInt(dado);
}

const fixaCasasDecimais = (valor, casasDecimais) => {
    if(valor === valorNil) 
        return valorNil;
    return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
}

function Tabela({ indice, estadoSelecionado, t, theme, campoOrdem, setCampoOrdem, decrescente, setDecrescente, handleChangeEstadoTabela, handleChangeMunicipioTabela, tabelaOrdenada, exportarDados, versaoResumida}) {

    return (
        <div className={exportarDados ? style.ocultar : style.table}>
            <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo") : t("dashboard.table.titulo_estados")}</center></h5></div>
            <Table id={exportarDados ? "dtBasicExampleExport" :  "dtBasicExample"} striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                <thead>
                    <tr>
                        <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("estado")
                        }}>
                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("municipio")
                            }}>
                            <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                        </th>

                        <th><a href='#' style={{ color: campoOrdem === "total_gripal" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "total_gripal") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("total_gripal")
                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col7"} />  </a></th>
                        <th><a href='#' style={{ color: campoOrdem === "total_geral" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "total_geral") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("total_geral")
                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col8"} />  </a></th>
                        
                        {(!versaoResumida) &&
                            <th><a href='#' style={{ color: campoOrdem === "taxagripais_atendtotais" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "taxagripais_atendtotais") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("taxagripais_atendtotais")
                            }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col6"} /></a></th>
                        }

                        <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                            if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                            setCampoOrdem("dqi")
                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.dqi"} /> </a>
                        </th>

                        {(!versaoResumida) &&
                            <th><a href='#' style={{ color: campoOrdem === "perc_completude" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "perc_completude") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("perc_completude")
                            }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_completude"} /> </a>
                            </th>
                        }
                        {(!versaoResumida) &&
                            <th><a href='#' style={{ color: campoOrdem === "diff_2w" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "diff_2w") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("diff_2w")
                            }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_tempestividade"} /> </a>
                            </th>
                        }
                        {(!versaoResumida) &&
                            <th>
                                <a href='#' style={{ color: campoOrdem === "prob_gro_trend_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "prob_gro_trend_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("prob_gro_trend_ivas")
                                }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.probabilidade_crescimento"} /> %  </a>
                            </th>
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&   
                            <th><a href='#' style={{ color: campoOrdem === "sinal_evi_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "sinal_evi_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("sinal_evi_consec_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_evi"} /> </a>
                            </th>
                        }
                        
                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_evi_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />   <Translator path={"dashboard.table.evi"} />
                                </a>
                            </th>

                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_evi_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} /> <Translator path={"dashboard.table.evi"} />  </a>
                            </th>
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) &&   
                            <th><a href='#' style={{ color: campoOrdem === "sinal_ears_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "sinal_ears_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("sinal_ears_consec_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ears"} />  </a>
                            </th>
                        }  
                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ears_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />  <Translator path={"dashboard.table.ears"} />  
                                </a>
                            </th>     
                        }  

                        
                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ears_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} />  <Translator path={"dashboard.table.ears"} />  </a>
                            </th>
                        } 

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) &&      
                            <th>
                                <a href='#' style={{ color: campoOrdem === "sinal_ambos_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "sinal_ambos_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("sinal_ambos_consec_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ambos"} />  
                                </a>
                            </th>  
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ambos_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ambos_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ambos_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite_ambos"} />  
                                </a>
                            </th>     
                        } 

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ambos_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ambos_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ambos_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso_ambos"} />  </a>
                            </th>
                        }    

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) &&   
                            <th><a href='#' style={{ color: campoOrdem === "sinal_ens_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "sinal_ens_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("sinal_ens_consec_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ens"} />  </a>
                            </th>
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ens_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ens_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ens_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />  <Translator path={"dashboard.table.ens"} />  
                                </a>
                            </th>     
                        }  

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ens_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ens_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ens_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} />  <Translator path={"dashboard.table.ens"} />  </a>
                            </th>
                        }   
                        
                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) &&      
                            <th>
                                <a href='#' style={{ color: campoOrdem === "sinal_ml_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "sinal_ml_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("sinal_ml_consec_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ml"} />  
                                </a>
                            </th>  
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_ml_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_ml_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_ml_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite_ml"} />  
                                </a>
                            </th>     
                        } 

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_ml_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_ml_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_ml_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso_ml"} />  </a>
                            </th>
                        } 

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) &&      
                            <th>
                                <a href='#' style={{ color: campoOrdem === "sinal_glm_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "sinal_glm_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("sinal_glm_consec_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_glm"} />  
                                </a>
                            </th>  
                        }

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) && 
                            <th>
                                <a href='#' style={{ color: campoOrdem === "limite_superior_glm_ivas" ? "cadetblue" : ""}} onClick={() => {
                                    if (campoOrdem === "limite_superior_glm_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                    setCampoOrdem("limite_superior_glm_ivas")
                                    }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite_glm"} />  
                                </a>
                            </th>     
                        } 

                        {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) &&
                            <th><a href='#' style={{ color: campoOrdem === "excesso_glm_ivas" ? "cadetblue" : ""}} onClick={() => {
                                if (campoOrdem === "excesso_glm_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                setCampoOrdem("excesso_glm_ivas")
                            }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso_glm"} />  </a>
                            </th>
                        }
                        
                    </tr>
                </thead>
                <tbody>
                    {tabelaOrdenada().map((value, index, array) => {
                        const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                      
                        return (
                            <tr key={index}>
                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ /*isMesmoEstado && index > 0 ? "" : */value["uf"]}</a></td>
                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_gripal"]))}</td>
                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_geral"]))}</td>        
                                {(!versaoResumida) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["taxagripais_atendtotais"]), 2)}</td>
                                }

                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["dqi"] === "Apto" ? t("dashboard.apto") : t("dashboard.nao_apto")}</td>
                                {(!versaoResumida) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["perc_completude"]),2)}</td>
                                }
                                {(!versaoResumida) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["diff_2w"]),2)}</td>
                                } 
                                {(!versaoResumida) &&     
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["prob_gro_trend_ivas"] === null ? valorNil :fixaCasasDecimais(trataNaNeNull(value["prob_gro_trend_ivas"] * 100),2)}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_evi_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_evi_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_evi_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_evi_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "evi")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_evi_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_ears_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ears_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_ears_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ears_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ears")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_ears_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_ambos_consec_ivas"] === null || value["sinal_ambos_consec_ivas"] === undefined ? valorNil : (value["sinal_ambos_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ambos_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_ivas"] === null ? valorNil : (value["limite_superior_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ambos")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_ambos_ivas"] === null ? valorNil : (value["excesso_ambos_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ambos_ivas"]))}</td>
                                }

                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_ens_consec_ivas"] === null || value["sinal_ens_consec_ivas"] === undefined ? valorNil : (value["sinal_ens_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ens_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_ens_ivas"] === null ? valorNil : (value["limite_superior_ens_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_ens_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ens")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_ens_ivas"] === null ? valorNil : (value["excesso_ens_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ens_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_ml_consec_ivas"] === null || value["sinal_ml_consec_ivas"] === undefined ? valorNil : (value["sinal_ml_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ml_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_ml_ivas"] === null ? valorNil : (value["limite_superior_ml_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_ml_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "ml")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_ml_ivas"] === null ? valorNil : (value["excesso_ml_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ml_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["sinal_glm_consec_ivas"] === null || value["sinal_glm_consec_ivas"] === undefined ? valorNil : (value["sinal_glm_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_glm_consec_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["limite_superior_glm_ivas"] === null ? valorNil : (value["limite_superior_glm_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["limite_superior_glm_ivas"]))}</td>
                                }
                                {(!versaoResumida && (exportarDados || indice.toLowerCase() === "glm")) &&
                                    <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["excesso_glm_ivas"] === null ? valorNil : (value["excesso_glm_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_glm_ivas"]))}</td>
                                }

                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            
        </div>
    );
}

export default Tabela;